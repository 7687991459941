import React from 'react'
import { Tooltip } from 'antd'
import { connect, useDispatch } from 'react-redux'
import LanguageSwitcher from './LanguageSwitcher'
import Glossary from './Glossary'
import UserMenu from './UserMenu'
import Breadcrumbs from '../Breadcrumbs'
import style from './style.module.scss'

const mapStateToProps = ({ settings, user }) => ({
  theme: settings.theme,
  mode: settings.menuColor,
  user,
})

const TopBar = ({ locale, trans, theme, user, mode }) => {
  const dispatch = useDispatch()
  const changeSetting = (setting, value) => {
    dispatch({
      type: 'settings/CHANGE_SETTING',
      payload: {
        setting,
        value,
      },
    })

    dispatch({
      type: 'user/SET_STATE',
      payload: {
        ...user,
        photo:
          !user.authorized && mode === 'dark'
            ? 'resources/images/avatars/avatar-2.png'
            : 'resources/images/avatars/avatar.png',
      },
    })
  }

  return (
    <div className={style.topbar}>
      {/* <Tutorial locale={locale} /> */}
      <div className="px-3" style={{ flex: 'auto' }}>
        <Breadcrumbs trans={trans} />
      </div>
      <div className="mr-auto mr-md-1">{/* <Search /> */}</div>
      <div className="mr-4 d-none d-md-block">{/* <IssuesHistory /> */}</div>
      <div className="mr-auto d-xl-block d-none">{/* <ProjectManagement /> */}</div>
      <div className="mr-3 d-none d-sm-block ml-auto">{/* <Cart /> */}</div>
      <div className="mr-3 d-none d-sm-block">
        <Tooltip title={trans('toggleGloss')} placement="top">
          <Glossary locale={locale} trans={trans} />
        </Tooltip>
      </div>
      <div className="mr-3 d-none d-sm-block">
        <Tooltip title={trans('switchLang')} placement="top">
          <LanguageSwitcher trans={trans} />
        </Tooltip>
      </div>
      <div className="mr-3 d-none d-sm-block">
        <Tooltip title={trans('switchTheme')} placement="bottom">
          <a
            role="button"
            tabIndex="0"
            onKeyUp={() => changeSetting('theme', theme === 'default' ? 'dark' : 'default')}
            onClick={() => changeSetting('theme', theme === 'default' ? 'dark' : 'default')}
            className="vb__utils__sidebar__button t-4"
          >
            {theme === 'default' && <i className="fa fa-moon" />}
            {theme !== 'default' && <i className="fa fa-sun" />}
          </a>
        </Tooltip>
      </div>
      <div className="">
        <UserMenu trans={trans} />
      </div>
    </div>
  )
}

export default connect(mapStateToProps)(TopBar)
