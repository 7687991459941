import i18n from 'i18next'
import XHR from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

// common translations
import commonEn from 'locales/en'
import commonFr from 'locales/fr'
// import commonEs from 'locales/es'
// import commonAr from 'locales/ar'

const resources = {
  en: { common: commonEn },
  fr: { common: commonFr },
  // es: { common: commonEs }, // update to spanish
  // ar: { common: commonEn }, // update to arabic
}

const options = {
  order: ['querystring', 'cookie', 'localStorage', 'sessionStorage', 'navigator'],
  lookupQuerystring: 'lng',
  lookupCookie: 'i18next',
  lookupLocalStorage: 'i18nextLng',
  lookupSessionStorage: 'i18nextLng',
}

i18n
  .use(LanguageDetector)
  .use(XHR)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // whitelist: ['en', 'fr', 'es'],
    supportedLngs: ['en', 'fr', 'es', 'ar'],
    detection: options,
    resources,
    // lng: 'en',
    ns: ['common'],
    defaultNS: 'common',
    fallbackLng: 'en',
    // keySeparator: false, //  we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false, //  react already safes from xss
    },
    react: {
      useSuspense: false, //  Prevent errors when some components are wrapped in React.Suspense
    },
  })

export default i18n
