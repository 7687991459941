const translations = {
  all: {
    acidRain: {
      title: 'Pluie acide',
      desc: `Pluies rendues si acides par la pollution atmosphérique qu'elles causent des dommages environnementaux, principalement aux forêts et aux lacs. La principale cause est la combustion industrielle du charbon et d'autres combustibles fossiles, dont les gaz résiduels contiennent des oxydes de soufre et d'azote qui se combinent avec l'eau atmosphérique pour former des acides.`,
    },
    acidification: {
      title: 'Acidification',
      desc: `Des quantités excessives d'acide sulfurique et nitrique se forment à partir des émissions d'oxydes de soufre et d'azote dans l'atmosphère et se déposent dans l'environnement .`,
    },
    biofuel: {
      title: 'Biocarburant',
      desc: `Carburant pour véhicule dérivé de la biomasse, c'est-à-dire des céréales, de la canne à sucre, du maïs ou du bois.`,
    },
    biomethanol: {
      title: 'Bioéthanol',
      desc:
        'Éthanol dérivé de la biomasse, généralement de la canne à sucre, du maïs ou des céréales.',
    },
    biodiesel: {
      title: 'Biodiesel',
      desc: `Diesel dérivé de la biomasse, généralement du colza ou d'autres huiles végétales.`,
    },
    cc: {
      title: 'Convertisseur catalytique',
      desc: `Dispositif de contrôle des émissions de gaz d'échappement qui convertit les gaz toxiques et les polluants des gaz d'échappement d'un moteur à combustion interne en polluants moins toxiques en catalysant une réaction d'oxydoréduction (une réaction d'oxydation et une réaction de réduction).`,
    },
    co: {
      abbr: 'CO',
      title: 'Monoxyde de carbone (CO)',
      desc: `Gaz incolore, inodore et insipide - mais toxique - formé lors d'une combustion incomplète. Le CO est extrêmement dangereux s'il est inhalé à des concentrations élevées, car il a une plus grande affinité que l'oxygène pour les globules rouges (hémoglobine), ce qui réduit sa capacité à transporter l'oxygène vers les organes vitaux.`,
    },
    co2: {
      abbr: 'CO2',
      title: 'Dioxyde de carbone (CO2)',
      desc:
        'Gaz incolore formé lors de la combustion de toute matière contenant du carbone et qui constitue un important gaz à effet de serre.',
    },
    cvd: {
      title: 'Maladies cardiovasculaires',
      desc: 'Maladies qui affectent le cœur et le système circulatoire.',
    },
    climchange: {
      title: 'Changement climatique',
      desc: `un processus par lequel les activités humaines entraînant l'émission de gaz à effet de serre (principalement du CO2 et du méthane) modifient le climat à long terme. Ce phénomène est également appelé «réchauffement de la planète»`,
    },
    cng: {
      title: 'Gaz naturel compressé (GNC)',
      desc:
        'Carburant de véhicule plus propre utilisé dans les moteurs à combustion normale adaptés.',
    },
    dp: {
      title: 'Modèle de dispersion ',
      desc:
        'Modèle qui décrit la dispersion des émissions des véhicules dans le milieu environnant.',
    },
    drm: {
      title: 'Modèle dose-réponse',
      desc: `Modèle qui décrit la relation entre la concentration d'un certain polluant et les effets sur la santé des personnes.`,
    },
    doc: {
      title: `Catalyseur d'oxydation diesel (COD)`,
      desc:
        ' Réduit les émissions de particules et d’hydrocarbures des véhicules diesel. Nécessite un diesel contenant moins de 500 parties par million de soufre.',
    },
    dpf: {
      title: 'Filtre à particules diesel (FPD)',
      desc:
        'Réduit les émissions de particules et d’hydrocarbures des véhicules diesel. Nécessite un diesel à faible ou ultra faible teneur en soufre (50 parties par million ou moins).',
    },
    driveTrain: {
      title: 'Groupe motopropulseur',
      desc: `Comprend le système de conversion de l'énergie et de distribution de la puissance dans le véhicule, ou plus simplement, le moteur et la transmission sur un véhicule normal (une transmission mécanique). Un groupe motopropulseur électrique utilisée dans un véhicule hybride, un véhicule à pile à combustible ou un véhicule électrique à batterie se compose d'un moteur électrique, d'un système électronique de puissance, d'une batterie de puissance et d'une transmission.`,
    },
    eco: {
      title: 'Écoconduite',
      desc:
        'Éducation à un meilleur comportement de conduite qui réduit la consommation de carburant, les émissions et les accidents.',
    },
    egr: {
      title: `Recirculation des gaz d'échappement (RGE)`,
      desc: `Système d'échappement qui réduit les émissions de NOx des véhicules diesel.`,
    },
    ev: {
      title: 'Véhicule électrique (VE)',
      desc: `Véhicule doté d'un groupe motopropulseur électrique. Il peut s'agir d'un véhicule électrique alimenté uniquement par une batterie, d'un véhicule électrique hybride (utilisant à la fois une batterie et une combustion de combustible fossile) ou d'un véhicule électrique à pile à combustible.`,
    },
    ef: {
      title: `Facteurs d'émission`,
      desc: `L'émission par kilomètre parcouru dans des conditions normales pour les véhicules, généralement mesurée en g/km.`,
    },
    tuneup: {
      title: ' Mise au point du moteur',
      desc: `Optimiser les performances du moteur en calibrant le système d'allumage, le système d'alimentation en air et le système d'alimentation en carburant.`,
    },
    euro: {
      title: 'Normes européennes ',
      desc: `Normes d'émission de l'Union européenne (en g/km). Commencées avec Euro 1 au début des années 1990, elles sont devenues progressivement plus strictes.`,
    },
    Eutrophication: {
      title: ' Eutrophisation ',
      desc: `Ce phénomène est également appelé "pollution par les nutriments" et est causé en partie par les émissions d'oxyde d'azote.`,
    },
    fcev: {
      title: 'Véhicule électrique à pile à combustible (VEPC)',
      desc: `Utilise une pile à combustible au lieu d'un moteur à combustion, voir ci-dessous.`,
    },
    // ftf: {
    //   title: '',
    //   desc: '',
    // },
    fuelcell: {
      title: 'Pile à combustible',
      desc: `Moteur chimique qui convertit l'hydrogène (H2) en électricité et en eau avec un rendement élevé.`,
    },
    gg: {
      title: ' Gaz à effet de serre',
      desc: ' Gaz qui contribue au réchauffement de la planète et donc au changement climatique.',
    },
    hev: {
      title: 'Véhicule électrique hybride (VEH)',
      desc:
        'Combine un moteur électrique avec un moteur à combustion. Le véhicule peut rouler sur une batterie rechargeable pendant une partie du temps, réduisant ainsi la consommation de carburant. Voir aussi VEHR  (Hybrides rechargeables).',
    },
    hc: {
      title: 'Hydrocarbures (HC)',
      desc: `Polluant formé par la combustion. Avec les NOx et sous l'influence de la lumière du soleil, les HC peuvent former de l'ozone troposphérique.`,
    },
    icev: {
      title: 'Véhicule à moteur à combustion interne (VMCI)',
      desc: `Véhicule conventionnel d'aujourd'hui équipé d'un moteur à combustion.`,
    },
    lsd: {
      title: 'Diesel à faible teneur en soufre',
      desc: 'Diesel contenant moins de 50 parties par million (ppm).',
    },
    nox: {
      abbr: 'NOx',
      title: `Oxydes d'azote (NOX)`,
      desc: `NOx est le nom commun de l'oxyde d'azote (NO) et du dioxyde d'azote (NO2).`,
    },
    // noxAd: {
    //   title: '',
    //   desc: '',
    // },
    ozone: {
      title: 'Ozone (03)',
      desc: `L'ozone se trouve dans la basse atmosphère (au niveau du sol) et plus haut dans la stratosphère (ozone stratosphérique). L'ozone troposphérique est l'un des principaux composants du smog photochimique et nuit à la santé humaine.`,
    },
    pb: {
      title: 'Plomb (PB)',
      desc: `Le plomb est un métal mou de couleur bleuâtre ou gris argenté. Deux de ses formes les plus importantes en termes de pollution atmosphérique sont le plomb tétraéthyle et le plomb tétraméthyle. Ces deux formes de plomb sont utilisées comme additifs "antidétonants" dans l'essence.`,
    },
    phev: {
      title: 'Véhicules hybrides rechargeables (VHR)',
      desc: `Véhicule électrique hybride pouvant fonctionner à l'essence ou au diesel et pouvant également être rechargé en électricité à partir du réseau électrique.`,
    },
    pm: {
      title: 'Matières particulaires  (MP)',
      desc: `Particules ou petites particules émises par les gaz d'échappement.`,
    },
    pm10: {
      title: 'PM 10',
      desc: `Particules d'une taille inférieure à 10 microns.`,
    },
    pm25: {
      title: 'PM 2.5',
      desc: 'Particules de taille inférieure à 2,5 microns.',
    },

    unleaded: {
      title: 'Essence sans plomb',
      desc: `Essence sans plomb ajouté. La norme dans presque tous les pays aujourd'hui.`,
    },
    ulsd: {
      title: 'Diesel à très faible teneur en soufre (DFTS)',
      desc: `Diesel à très faible teneur en soufre (moins de 15 ppm). La norme dans l'UE et aux États-Unis.`,
    },
    respdisease: {
      title: 'Maladies respiratoires',
      desc: 'Maladies qui affectent le système pulmonaire.',
    },
    retrofit: {
      title: 'Modernisation',
      desc: `installation d'une technologie de contrôle des émissions de gaz d'échappement sur un véhicule existant`,
    },
    scr: {
      title: '',
      desc: '',
    },
    solarh2: {
      title: 'Hydrogène solaire',
      desc: `Hydrogène dérivé de l'énergie solaire par électrolyse de l'eau (électricité solaire + eau = hydrogène + oxygène)`,
    },
    so2: {
      abbr: 'SO2',
      abbr2: 'SOx',
      title: 'Dioxyde de soufre (SO2)',
      desc: `Un gaz lourd et incolore à l'odeur piquante, également connu pour être un irritant. Le SO2 provoque une acidification.`,
    },
    spm: {
      title: 'Matières particulaires en suspension (MPS',
      desc: `Il s'agit de particules de toutes tailles présentes dans l'air. Les MPS sont un mélange complexe de substances organiques, présentes dans l'atmosphère à la fois sous forme de particules solides et de gouttelettes liquides. Elles comprennent les fumées, la fumée, la poussière et les aérosols.`,
    },
    threeWaycat: {
      title: 'Un catalyseur à trois voies',
      desc: `Catalyseur qui réduit les trois émissions - hydrocarbures, oxydes d'azote et monoxyde de carbone - des véhicules à essence. Nécessite de l'essence sans plomb.`,
    },
    twoStroke: {
      title: 'Moteur à deux temps',
      desc: `Ancien type de moteur encore utilisé dans les petites applications telles que les petites mobylettes, les tondeuses à gazon et les motos anciennes. Aujourd'hui, la plupart des moteurs sont des moteurs à 4 temps. "2 temps" fait référence au nombre de mouvements linéaires du piston entre chaque allumage.`,
    },
    alignment: {
      title: 'Alignement des roues',
      desc: 'Correction des roues qui ne sont pas alignées (parallèles).',
    },
    voc: {
      abbr: 'VOC',
      title: 'Composés organiques volatils (VOC)',
      desc: `Comprend un large éventail de substances individuelles, notamment des hydrocarbures (alcènes, alcènes et aromatiques), des halocarbures (par exemple, le trichloréthylène) et des composés oxygénés (alcools, aldéhydes et hétoniques). Ce sont tous des composés organiques et ils sont suffisamment volatils pour exister sous forme de vapeur dans l'atmosphère en conditions normales.`,
    },
  },
}

export default translations
