import React, { lazy, Suspense } from 'react'
import { Route, Redirect, Switch } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import { connect } from 'react-redux'

import Layout from 'layouts'

const routes = [
  // VB:REPLACE-START:ROUTER-CONFIG
  {
    path: '/home',
    Component: lazy(() => import('pages/home')),
    exact: true,
  },
  {
    path: '/profile',
    Component: lazy(() => import('pages/profile')),
    exact: true,
  },
  {
    path: '/commitments',
    Component: lazy(() => import('pages/profile/commitments')),
    exact: true,
  },
  {
    path: '/commitments2',
    Component: lazy(() => import('pages/profile/commitmentsNew')),
    exact: true,
  },
  {
    path: '/air-pollution-and-transport',
    Component: lazy(() => import('pages/air-pollution-and-transport')),
    exact: true,
  },
  {
    path: '/influence-of-technology-on-emissions',
    Component: lazy(() => import('pages/influence-of-technology-on-emissions')),
    exact: true,
  },
  {
    path: '/health-impacts',
    Component: lazy(() => import('pages/health-impacts')),
    exact: true,
  },
  {
    path: '/environmental-impacts',
    Component: lazy(() => import('pages/environmental-impacts')),
    exact: true,
  },
  {
    path: '/climate-change-and-the-greenhouse-effect',
    Component: lazy(() => import('pages/climate-change-and-the-greenhouse-effect')),
    exact: true,
  },
  {
    path: '/journey-management',
    Component: lazy(() => import('pages/journey-management')),
    exact: true,
  },
  {
    path: '/eco-driving',
    Component: lazy(() => import('pages/eco-driving')),
    exact: true,
  },
  {
    path: '/maintenance',
    Component: lazy(() => import('pages/maintenance')),
    exact: true,
  },
  {
    path: '/fuels',
    Component: lazy(() => import('pages/fuels')),
    exact: true,
  },
  {
    path: '/emission-control-for-diesel-vehicles',
    Component: lazy(() => import('pages/emission-control-for-diesel-vehicles')),
    exact: true,
  },
  {
    path: '/emission-control-for-petrol-vehicles',
    Component: lazy(() => import('pages/emission-control-for-petrol-vehicles')),
    exact: true,
  },
  {
    path: '/cleaner-vehicles',
    Component: lazy(() => import('pages/cleaner-vehicles')),
    exact: true,
  },
  {
    path: '/electric-vehicles',
    Component: lazy(() => import('pages/electric-vehicles')),
    exact: true,
  },
  {
    path: '/generator-alternatives',
    Component: lazy(() => import('pages/generator-alternatives')),
    exact: true,
  },
  {
    path: '/case-studies',
    Component: lazy(() => import('pages/case-studies')),
    exact: true,
  },
  {
    path: '/case-studies/cng-vs-electricity',
    Component: lazy(() => import('pages/case-studies/cng-vs-electricity')),
    exact: true,
  },
  {
    path: '/case-studies/dhl',
    Component: lazy(() => import('pages/case-studies/dhl')),
    exact: true,
  },
  {
    path: '/case-studies/fedex',
    Component: lazy(() => import('pages/case-studies/fedex')),
    exact: true,
  },
  {
    path: '/case-studies/jarkata',
    Component: lazy(() => import('pages/case-studies/jakarta')),
    exact: true,
  },
  {
    path: '/case-studies/tnt',
    Component: lazy(() => import('pages/case-studies/tnt')),
    exact: true,
  },
  {
    path: '/case-studies/wfp',
    Component: lazy(() => import('pages/case-studies/wfp')),
    exact: true,
  },
  {
    path: '/case-studies/unama',
    Component: lazy(() => import('pages/case-studies/unama')),
    exact: true,
  },
  {
    path: '/case-studies/unhcr',
    Component: lazy(() => import('pages/case-studies/unhcr')),
    exact: true,
  },
  {
    path: '/case-studies/unicef',
    Component: lazy(() => import('pages/case-studies/unicef')),
    exact: true,
  },
  {
    path: '/case-studies/ups',
    Component: lazy(() => import('pages/case-studies/ups')),
    exact: true,
  },
  {
    path: '/strategy-checklist',
    Component: lazy(() => import('pages/strategy-checklist')),
    exact: true,
  },

  // VB:REPLACE-END:ROUTER-CONFIG
  {
    path: '/auth/404',
    Component: lazy(() => import('pages/auth/404')),
    exact: true,
  },
  {
    path: '/auth/500',
    Component: lazy(() => import('pages/auth/500')),
    exact: true,
  },
]

const mapStateToProps = ({ settings }) => ({
  routerAnimation: settings.routerAnimation,
  locale: settings.locale,
})

const Router = ({ history, routerAnimation, locale }) => {
  return (
    <ConnectedRouter history={history}>
      <Layout>
        <Route
          render={(state) => {
            const { location } = state
            return (
              <SwitchTransition>
                <CSSTransition
                  key={location.pathname}
                  appear
                  classNames={routerAnimation}
                  timeout={routerAnimation === 'none' ? 0 : 300}
                >
                  <Switch location={location}>
                    {/* VB:REPLACE-NEXT-LINE:ROUTER-REDIRECT */}
                    <Route exact path="/" render={() => <Redirect to="/home" />} />
                    {routes.map(({ path, Component, exact }) => (
                      <Route
                        path={path}
                        key={path}
                        exact={exact}
                        render={() => {
                          return (
                            <div className={routerAnimation}>
                              <Suspense fallback={null}>
                                <Component locale={locale} />
                              </Suspense>
                            </div>
                          )
                        }}
                      />
                    ))}
                    <Redirect to="/auth/404" />
                  </Switch>
                </CSSTransition>
              </SwitchTransition>
            )
          }}
        />
      </Layout>
    </ConnectedRouter>
  )
}

export default connect(mapStateToProps)(Router)
