import React from 'react'
import { Dropdown, Tooltip } from 'antd'
import { connect } from 'react-redux'

import i18n from 'i18n'
import getMenuData from '../../../services/menu/index'
import styles from './style.module.scss'
import FlagEn from './flags/en.svg'
import FlagFr from './flags/fr.svg'
import FlagEs from './flags/es.svg'
import FlagAr from './flags/ar.svg'

const mapStateToProps = ({ settings }) => ({
  locale: settings.locale,
})

const LanguageSwitcher = ({ dispatch, locale, trans }) => {
  const mapFlags = {
    en: FlagEn,
    fr: FlagFr,
    es: FlagEs,
    ar: FlagAr,
  }

  const changeLanguage = async ({ key }) => {
    const menuData = await getMenuData(key)
    await i18n.changeLanguage(key).then(() => {
      dispatch({
        type: 'settings/CHANGE_SETTING',
        payload: {
          setting: 'locale',
          value: key,
        },
      })

      dispatch({
        type: 'menu/SET_STATE',
        payload: {
          menuData,
        },
      })

      dispatch({
        type: 'settings/CHANGE_SETTING',
        payload: {
          setting: 'direction',
          value: key === 'ar' ? 'rtl' : 'ltr',
        },
      })
      // set the html lang attribute
      document.documentElement.lang = key
    })
  }

  const items = [
    {
      key: 'en',
      label: (
        <>
          <span className={styles.menuIcon}>
            <img src={mapFlags.en} alt={trans('en')} />
          </span>
          {trans('en')}
        </>
      ),
    },
    {
      key: 'fr',
      label: (
        <>
          <span className={styles.menuIcon}>
            <img src={mapFlags.fr} alt={trans('fr')} />
          </span>
          {trans('fr')}
        </>
      ),
    },
    // {
    //   key: 'es',
    //   label: (
    //     <>
    //       <span className={styles.menuIcon}>
    //         <img src={mapFlags.es} alt={trans('es')} />
    //       </span>
    //       {trans('es')}
    //     </>
    //   ),
    // },
  ]

  const menuProps = {
    items,
    onClick: changeLanguage,
  }

  return (
    <Tooltip placement="topLeft" title={trans('switchLang')}>
      <Dropdown menu={menuProps} trigger={['click']} placement="bottomLeft">
        <div className={styles.dropdown}>
          <div className={styles.flag}>
            <img src={mapFlags[locale]} alt={locale} />
            <span>{locale}</span>
          </div>
        </div>
      </Dropdown>
    </Tooltip>
  )
}

export default connect(mapStateToProps)(LanguageSwitcher)
