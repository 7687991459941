import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  unleadedPetrol: Boolean(),
  shortTerm: {
    tripScheduling: Boolean(),
    ecoDrivingIncentives: Boolean(),
    carPooling: Boolean(),
    fewerVehicles: Boolean(),
    ecoDrivingTraining: Boolean(),
    improveRegularMaintenance: Boolean(),
    purchaseCleanerFuels: Boolean(),
  },
  midTerm: {
    requireCleanerFuels: Boolean(),
    replaceOlderVehicles: Boolean(),
    useSmallerVehicles: Boolean(),
    retrofitOlderVehicles: Boolean(),
    needAllVehicles: Boolean(),
  },
  longTerm: {
    buyAlternateVehicles: Boolean(),
    useRenewablePower: Boolean(),
  },
  management: {
    seniorMgrStrategy: Boolean(),
    dataCollection: Boolean(),
    communicateResults: Boolean(),
    rightPartners: Boolean(),
    progressEvaluation: Boolean(),
  },
}

const strategySlice = createSlice({
  name: 'strategy',
  initialState,
  reducers: {
    unleadedPetrol: (state, action) => {
      state.unleadedPetrol = action.payload
    },
    shortTerm: (state, action) => {
      const [key, val] = Object.entries(action.payload)[0]
      state.shortTerm[key] = val
    },
    midTerm: (state, action) => {
      const [key, val] = Object.entries(action.payload)[0]
      state.midTerm[key] = val
    },
    longTerm: (state, action) => {
      const [key, val] = Object.entries(action.payload)[0]
      state.longTerm[key] = val
    },
    management: (state, action) => {
      const [key, val] = Object.entries(action.payload)[0]
      state.management[key] = val
    },
    resetStrategy: () => initialState,
  },
})

export const { unleadedPetrol, shortTerm, midTerm, longTerm, management, resetStrategy } =
  strategySlice.actions
export default strategySlice.reducer
