const backendPort = 3300
const envConstants = {
  local: {
    BACKEND_URL: `http://localhost:${backendPort}`,
    API_URL: `http://localhost:${backendPort}/api`,
    userId: '',
    role: 'Administrator',
    isAuthorized: false,
  },
  dev: {
    BACKEND_URL: 'https://backend.dev.fleetforum.org',
    API_URL: 'https://backend.dev.fleetforum.org/api',
    userId: '',
    isAuthorized: false,
  },
  live: {
    BACKEND_URL: 'https://backend.cleanfleet.fleetforum.org',
    API_URL: 'https://backend.cleanfleet.fleetforum.org/api',
    userId: '',
    isAuthorized: false,
  },
}

const env = 'live'

export const { BACKEND_URL } = envConstants[env]
export const { API_URL } = envConstants[env]
export const { userId } = envConstants[env]
export const { userName } = envConstants[env]
export const { isAuthorized } = envConstants[env]
export const { role } = envConstants[env]
