import { createSlice } from '@reduxjs/toolkit'
// import actions from './actions'

const initialState = {
  inputs: {
    hevMileage: Number(),
    hevAdditionalCost: Number(),
  },
  ecoDriving: {
    lowFuelSavings: Number(),
    highFuelSavings: Number(),
    lowFinancialSavings: Number(),
    highFinancialSavings: Number(),
    lowCo2Savings: Number(),
    highCo2Savings: Number(),
  },
  maintenance: {
    lowFuelSavings: Number(),
    highFuelSavings: Number(),
    lowFinancialSavings: Number(),
    highFinancialSavings: Number(),
    lowCo2Savings: Number(),
    highCo2Savings: Number(),
  },
  airCon: {
    lowFuelSavings: Number(),
    highFuelSavings: Number(),
    lowFinancialSavings: Number(),
    highFinancialSavings: Number(),
    lowCo2Savings: Number(),
    highCo2Savings: Number(),
  },
  cleanerFuels: {
    highSulphurLevelSOx: Number(),
    lowSulphurLevelSOx: Number(),
    soxHighReduction: Number(),
    soxLowReduction: Number(),
    soxHighReductionPct: Number(),
    soxLowReductionPct: Number(),
  },
  hevs: {
    numPassengerVehicles: Number(),
    passengerVehiclesMileage: Number(),
    passengerVehiclesPetrolConsumption: Number(),
    passengerVehiclesDieselConsumption: Number(),
    passengerPetrolHevFuelConsumption: Number(),
    passengerDieselHevFuelConsumption: Number(),
    passengerVehiclesFuelCost: Number(),
    passengerHevFuelCost: Number(),
    passengerVehiclesCO2: Number(),
    passengerHevCO2: Number(),
    passengerHevPaybackPeriod: Number(),
  },
  bevs: {
    bevMileage: Number(), // bevFuelConsumption
    bevAdditionalCost: Number(),
    passengerPetrolHevFuelConsumption: Number(),
    passengerDieselHevFuelConsumption: Number(),
    passengerVehiclesFuelCost: Number(),
    passengerHevFuelCost: Number(),
    passengerVehiclesCO2: Number(),
    passengerHevCO2: Number(),
    passengerHevPaybackPeriod: Number(),
  },
  fourStroke: {
    switchFourStrokeVOC: Number(),
    fourStrokeVOCSavings: Number(),
    switchFourStrokePM: Number(),
    switchFourStrokePMSavings: Number(),
    switchToFourStrokeFuel: Number(),
    switchToFourStrokeFuelHigh: Number(),
    switchToFourStrokeFuelSavings: Number(),
  },
  eBikes: {
    eBikeRange: Number(),
    eBikeAdditionalCost: Number(),
    eBikeSwitchEnergyCost: Number(),
    eBikeSwitchCO2: Number(),
    eBikeSwitchPaybackPeriod: Number(),
  },
  newTrucks: {
    bevTrucksMileage: Number(), // bevTrucksKmPerKWh
    switchHevLdvMdvFuelConsumption: Number(),
    switchBevLdvMdvEnergyConsumption: Number(),
    switchEuro5LdvMdvFuelConsumption: Number(),
    switchHevLdvMdvFuelCost: Number(),
    switchBevLdvMdvEnergyCost: Number(),
    switchEuro5LdvMdvFuelCost: Number(),
    switchHevLdvMdvCO2: Number(),
    switchBevLdvMdvCO2: Number(),
    switchEuro5LdvMdvCO2: Number(),
    switchHevHdvFuelConsumption: Number(),
    switchBevHdvEnergyConsumption: Number(),
    switchEuro5HdvFuelConsumption: Number(),
    switchHevHdvFuelCost: Number(),
    switchBevHdvEnergyCost: Number(),
    switchEuro5HdvFuelCost: Number(),
    switchHevHdvCO2: Number(),
    switchBevHdvCO2: Number(),
    switchEuro5HdvCO2: Number(),
  },
  retrofitTrucks: {
    retrofitDOC20pc: Number(),
    retrofitDOC40pc: Number(),
    retrofitDPF60pc: Number(),
    retrofitDPF90pc: Number(),
  },
  generatorAlt: {
    pvEnergyDemand: Number(),
    pvCostPerKWH: Number(),
  },
  co2offset: {
    fleetWideC02: Number(),
    treesLow: Number(),
    treesHigh: Number(),
    areaLow: Number(),
    areaHigh: Number(),
    areaLength: Number(),
    areaWidth: Number(),
  },
}

const recommendedActionsSlice = createSlice({
  name: 'recommendedActions',
  initialState,
  reducers: {
    inputs: (state, action) => {
      const [key, val] = Object.entries(action.payload)[0]
      state.inputs[key] = val
    },
    ecoDriving: (state, action) => {
      state.ecoDriving = action.payload
    },
    maintenance: (state, action) => {
      state.maintenance = action.payload
    },
    airCon: (state, action) => {
      state.airCon = action.payload
    },
    cleanerFuels: (state, action) => {
      state.cleanerFuels = action.payload
    },
    hevs: (state, action) => {
      state.hevs = action.payload
    },
    bevs: (state, action) => {
      state.bevs = action.payload
    },
    fourStroke: (state, action) => {
      state.fourStroke = action.payload
    },
    eBikes: (state, action) => {
      state.eBikes = action.payload
    },
    newTrucks: (state, action) => {
      state.newTrucks = action.payload
    },
    retrofitTrucks: (state, action) => {
      state.retrofitTrucks = action.payload
    },
    generatorAlt: (state, action) => {
      state.generatorAlt = action.payload
    },
    co2offset: (state, action) => {
      state.co2offset = action.payload
    },
    resetActions: () => initialState,
  },
})

export const {
  inputs,
  ecoDriving,
  maintenance,
  airCon,
  cleanerFuels,
  hevs,
  bevs,
  fourStroke,
  eBikes,
  newTrucks,
  retrofitTrucks,
  generatorAlt,
  co2offset,
  resetActions,
} = recommendedActionsSlice.actions
export default recommendedActionsSlice.reducer
