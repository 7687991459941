const locale = {
  SITETITLE: 'Clean Fleet Toolkit',
  en: 'English',
  fr: 'French',
  es: 'Spanish',
  ar: 'Arabic',
  home: 'Home',
  hello: 'Hello',
  anon: 'Anonymous',
  org: 'Organisation',
  role: 'Role',
  email: 'Email',
  phone: 'Phone',
  logout: 'Logout',
  glossary: 'Glossary of Terms',
  loading: 'Loading...',
  petrol: 'Petrol',
  diesel: 'Diesel',
  sulphurLevel: `Sulphur level`,
  unleaded: `Unleaded Petrol`,
  perYear: 'per year',
  viewProfile: 'View Profile',
  manCommitments: 'Manage Commitments',
  switchTheme: 'Switch Dark / Light Theme',
  switchLang: 'Change Language',
  toggleGloss: 'Toggle Glossary',
  footer: {
    title: 'Clean Fleet Toolkit Disclaimer',
    disclaimer: `This toolkit is designed to provide organizations insights into their fleet’s current
      emissions, impacts and costs, and the relative improvements that different options
      could provide. The calculations are based on estimations and average emission factors.
      The actual level of impacts, emissions, costs and savings will be influenced by a
      number of factors including: driver behavior; road conditions; traffic patterns;
      vehicle age and condition; maintenance etc.`,
    unep: '',
    privacy: '',
  },
  pageTitles: {
    calc: 'Emissions Calculator Tool',
    air: 'Air Pollution and Transport',
    tech: 'Influence of Technology on Emissions',
    health: 'Health Impacts',
    env: 'Environmental Impacts',
    climate: 'Climate Change and the Greenhouse Effect',
    journey: 'Journey Management',
    eco: 'Eco Driving',
    maint: 'Maintenance',
    fuels: 'Fuels',
    diesel: 'Emission Control for Diesel Vehicles',
    petrol: 'Emission Control for Petrol Vehicles',
    cleanv: 'Cleaner Vehicles',
    evs: 'Electric Vehicles',
    genalt: 'Generator Alternatives',
    cases: 'Case Studies',
    strategy: 'Strategy Checklist',
    cng: 'CNG vs Electricity',
    dhl: 'DHL Case Study',
    fedex: 'Fedex Case Study',
    jarkata: 'Jakarta Case Study',
    tnt: 'TNT Case Study',
    wfp: 'WFP Case Study',
    unama: 'UNAMA Case Study',
    unhcr: 'UNHCR Case Study',
    unicef: 'UNICEF Case Study',
    ups: 'UPS Case Study',
  },
}

export default locale
