import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import user from './user/reducers'
import menu from './menu/reducers'
import settings from './settings/reducers'
import pages from './pages/reducers'
import inventory from './calculator/inventoryReducer'
import emissions from './calculator/emissionsReducer'
import strategy from './calculator/strategyReducer'
import recomendedActions from './calculator/recomendedActionsReducer'
import currencies from './calculator/currenciesReducer'
import countries from './calculator/countriesReducer'
import commitmentsReducer from './commitments/commitmentsSlice'

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    user,
    menu,
    settings,
    pages,
    inventory,
    emissions,
    strategy,
    recomendedActions,
    currencies,
    countries,
    commitments: commitmentsReducer
  })
