import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  currentStep: 0,
  passengerCarsGasoline: {
    preEuroEmissions: {
      CO: Number(),
      VOC: Number(),
      NOx: Number(),
      SOx: Number(),
      PM10: Number(),
      CO2: Number(),
      scopedCO2: {
        scope1: Number(),
        scope2: Number(),
        scope3: Number(),
      },
    },
    euro1Emissions: {
      CO: Number(),
      VOC: Number(),
      NOx: Number(),
      SOx: Number(),
      PM10: Number(),
      CO2: Number(),
      scopedCO2: {
        scope1: Number(),
        scope2: Number(),
        scope3: Number(),
      },
    },
    euro2Emissions: {
      CO: Number(),
      VOC: Number(),
      NOx: Number(),
      SOx: Number(),
      PM10: Number(),
      CO2: Number(),
      scopedCO2: {
        scope1: Number(),
        scope2: Number(),
        scope3: Number(),
      },
    },
    euro3Emissions: {
      CO: Number(),
      VOC: Number(),
      NOx: Number(),
      SOx: Number(),
      PM10: Number(),
      CO2: Number(),
      scopedCO2: {
        scope1: Number(),
        scope2: Number(),
        scope3: Number(),
      },
    },
    euro4Emissions: {
      CO: Number(),
      VOC: Number(),
      NOx: Number(),
      SOx: Number(),
      PM10: Number(),
      CO2: Number(),
      scopedCO2: {
        scope1: Number(),
        scope2: Number(),
        scope3: Number(),
      },
    },
    euro5Emissions: {
      CO: Number(),
      VOC: Number(),
      NOx: Number(),
      SOx: Number(),
      PM10: Number(),
      CO2: Number(),
      scopedCO2: {
        scope1: Number(),
        scope2: Number(),
        scope3: Number(),
      },
    },
    euro6Emissions: {
      CO: Number(),
      VOC: Number(),
      NOx: Number(),
      SOx: Number(),
      PM10: Number(),
      CO2: Number(),
      scopedCO2: {
        scope1: Number(),
        scope2: Number(),
        scope3: Number(),
      },
    },
    totals: {
      CO: Number(),
      VOC: Number(),
      NOx: Number(),
      SOx: Number(),
      PM10: Number(),
      CO2: Number(),
      scopedCO2: {
        scope1: Number(),
        scope2: Number(),
        scope3: Number(),
      },
    },
  },
  passengerCarsDiesel: {
    euro3Emissions: {
      CO: Number(),
      VOC: Number(),
      NOx: Number(),
      SOx: Number(),
      PM10: Number(),
      CO2: Number(),
      scopedCO2: {
        scope1: Number(),
        scope2: Number(),
        scope3: Number(),
      },
    },
    euro4Emissions: {
      CO: Number(),
      VOC: Number(),
      NOx: Number(),
      SOx: Number(),
      PM10: Number(),
      CO2: Number(),
      scopedCO2: {
        scope1: Number(),
        scope2: Number(),
        scope3: Number(),
      },
    },
    euro5Emissions: {
      CO: Number(),
      VOC: Number(),
      NOx: Number(),
      SOx: Number(),
      PM10: Number(),
      CO2: Number(),
      scopedCO2: {
        scope1: Number(),
        scope2: Number(),
        scope3: Number(),
      },
    },
    euro6Emissions: {
      CO: Number(),
      VOC: Number(),
      NOx: Number(),
      SOx: Number(),
      PM10: Number(),
      CO2: Number(),
      scopedCO2: {
        scope1: Number(),
        scope2: Number(),
        scope3: Number(),
      },
    },
    totals: {
      CO: Number(),
      VOC: Number(),
      NOx: Number(),
      SOx: Number(),
      PM10: Number(),
      CO2: Number(),
      scopedCO2: {
        scope1: Number(),
        scope2: Number(),
        scope3: Number(),
      },
    },
  },
  passengerCarsElectric: {
    hevEmissions: {
      CO: Number(),
      VOC: Number(),
      NOx: Number(),
      SOx: Number(),
      PM10: Number(),
      CO2: Number(),
      scopedCO2: {
        scope1: Number(),
        scope2: Number(),
        scope3: Number(),
      },
    },
    phevEmissions: {
      CO: Number(),
      VOC: Number(),
      NOx: Number(),
      SOx: Number(),
      PM10: Number(),
      CO2: Number(),
      scopedCO2: {
        scope1: Number(),
        scope2: Number(),
        scope3: Number(),
      },
    },
    bevEmissions: {
      CO: Number(),
      VOC: Number(),
      NOx: Number(),
      SOx: Number(),
      PM10: Number(),
      CO2: Number(),
      scopedCO2: {
        scope1: Number(),
        scope2: Number(),
        scope3: Number(),
      },
    },
    totals: {
      CO: Number(),
      VOC: Number(),
      NOx: Number(),
      SOx: Number(),
      PM10: Number(),
      CO2: Number(),
      scopedCO2: {
        scope1: Number(),
        scope2: Number(),
        scope3: Number(),
      },
    },
  },
  passengerSUVsGasoline: {
    preEuroEmissions: {
      CO: Number(),
      VOC: Number(),
      NOx: Number(),
      SOx: Number(),
      PM10: Number(),
      CO2: Number(),
      scopedCO2: {
        scope1: Number(),
        scope2: Number(),
        scope3: Number(),
      },
    },
    euro1Emissions: {
      CO: Number(),
      VOC: Number(),
      NOx: Number(),
      SOx: Number(),
      PM10: Number(),
      CO2: Number(),
      scopedCO2: {
        scope1: Number(),
        scope2: Number(),
        scope3: Number(),
      },
    },
    euro2Emissions: {
      CO: Number(),
      VOC: Number(),
      NOx: Number(),
      SOx: Number(),
      PM10: Number(),
      CO2: Number(),
      scopedCO2: {
        scope1: Number(),
        scope2: Number(),
        scope3: Number(),
      },
    },
    euro3Emissions: {
      CO: Number(),
      VOC: Number(),
      NOx: Number(),
      SOx: Number(),
      PM10: Number(),
      CO2: Number(),
      scopedCO2: {
        scope1: Number(),
        scope2: Number(),
        scope3: Number(),
      },
    },
    euro4Emissions: {
      CO: Number(),
      VOC: Number(),
      NOx: Number(),
      SOx: Number(),
      PM10: Number(),
      CO2: Number(),
      scopedCO2: {
        scope1: Number(),
        scope2: Number(),
        scope3: Number(),
      },
    },
    euro5Emissions: {
      CO: Number(),
      VOC: Number(),
      NOx: Number(),
      SOx: Number(),
      PM10: Number(),
      CO2: Number(),
      scopedCO2: {
        scope1: Number(),
        scope2: Number(),
        scope3: Number(),
      },
    },
    euro6Emissions: {
      CO: Number(),
      VOC: Number(),
      NOx: Number(),
      SOx: Number(),
      PM10: Number(),
      CO2: Number(),
      scopedCO2: {
        scope1: Number(),
        scope2: Number(),
        scope3: Number(),
      },
    },
    totals: {
      CO: Number(),
      VOC: Number(),
      NOx: Number(),
      SOx: Number(),
      PM10: Number(),
      CO2: Number(),
      scopedCO2: {
        scope1: Number(),
        scope2: Number(),
        scope3: Number(),
      },
    },
  },
  passengerSUVsDiesel: {
    preEuroEmissions: {
      CO: Number(),
      VOC: Number(),
      NOx: Number(),
      SOx: Number(),
      PM10: Number(),
      CO2: Number(),
      scopedCO2: {
        scope1: Number(),
        scope2: Number(),
        scope3: Number(),
      },
    },
    euro1Emissions: {
      CO: Number(),
      VOC: Number(),
      NOx: Number(),
      SOx: Number(),
      PM10: Number(),
      CO2: Number(),
      scopedCO2: {
        scope1: Number(),
        scope2: Number(),
        scope3: Number(),
      },
    },
    euro2Emissions: {
      CO: Number(),
      VOC: Number(),
      NOx: Number(),
      SOx: Number(),
      PM10: Number(),
      CO2: Number(),
      scopedCO2: {
        scope1: Number(),
        scope2: Number(),
        scope3: Number(),
      },
    },
    euro3Emissions: {
      CO: Number(),
      VOC: Number(),
      NOx: Number(),
      SOx: Number(),
      PM10: Number(),
      CO2: Number(),
      scopedCO2: {
        scope1: Number(),
        scope2: Number(),
        scope3: Number(),
      },
    },
    euro4Emissions: {
      CO: Number(),
      VOC: Number(),
      NOx: Number(),
      SOx: Number(),
      PM10: Number(),
      CO2: Number(),
      scopedCO2: {
        scope1: Number(),
        scope2: Number(),
        scope3: Number(),
      },
    },
    euro5Emissions: {
      CO: Number(),
      VOC: Number(),
      NOx: Number(),
      SOx: Number(),
      PM10: Number(),
      CO2: Number(),
      scopedCO2: {
        scope1: Number(),
        scope2: Number(),
        scope3: Number(),
      },
    },
    euro6Emissions: {
      CO: Number(),
      VOC: Number(),
      NOx: Number(),
      SOx: Number(),
      PM10: Number(),
      CO2: Number(),
      scopedCO2: {
        scope1: Number(),
        scope2: Number(),
        scope3: Number(),
      },
    },
    totals: {
      CO: Number(),
      VOC: Number(),
      NOx: Number(),
      SOx: Number(),
      PM10: Number(),
      CO2: Number(),
      scopedCO2: {
        scope1: Number(),
        scope2: Number(),
        scope3: Number(),
      },
    },
  },
  passengerSUVsElectric: {
    hevEmissions: {
      CO: Number(),
      VOC: Number(),
      NOx: Number(),
      SOx: Number(),
      PM10: Number(),
      CO2: Number(),
      scopedCO2: {
        scope1: Number(),
        scope2: Number(),
        scope3: Number(),
      },
    },
    phevEmissions: {
      CO: Number(),
      VOC: Number(),
      NOx: Number(),
      SOx: Number(),
      PM10: Number(),
      CO2: Number(),
      scopedCO2: {
        scope1: Number(),
        scope2: Number(),
        scope3: Number(),
      },
    },
    bevEmissions: {
      CO: Number(),
      VOC: Number(),
      NOx: Number(),
      SOx: Number(),
      PM10: Number(),
      CO2: Number(),
      scopedCO2: {
        scope1: Number(),
        scope2: Number(),
        scope3: Number(),
      },
    },
    totals: {
      CO: Number(),
      VOC: Number(),
      NOx: Number(),
      SOx: Number(),
      PM10: Number(),
      CO2: Number(),
      scopedCO2: {
        scope1: Number(),
        scope2: Number(),
        scope3: Number(),
      },
    },
  },
  ldvGasoline: {
    preEuroEmissions: {
      CO: Number(),
      VOC: Number(),
      NOx: Number(),
      SOx: Number(),
      PM10: Number(),
      CO2: Number(),
      scopedCO2: {
        scope1: Number(),
        scope2: Number(),
        scope3: Number(),
      },
    },
    euro1Emissions: {
      CO: Number(),
      VOC: Number(),
      NOx: Number(),
      SOx: Number(),
      PM10: Number(),
      CO2: Number(),
      scopedCO2: {
        scope1: Number(),
        scope2: Number(),
        scope3: Number(),
      },
    },
    euro2Emissions: {
      CO: Number(),
      VOC: Number(),
      NOx: Number(),
      SOx: Number(),
      PM10: Number(),
      CO2: Number(),
      scopedCO2: {
        scope1: Number(),
        scope2: Number(),
        scope3: Number(),
      },
    },
    euro3Emissions: {
      CO: Number(),
      VOC: Number(),
      NOx: Number(),
      SOx: Number(),
      PM10: Number(),
      CO2: Number(),
      scopedCO2: {
        scope1: Number(),
        scope2: Number(),
        scope3: Number(),
      },
    },
    euro4Emissions: {
      CO: Number(),
      VOC: Number(),
      NOx: Number(),
      SOx: Number(),
      PM10: Number(),
      CO2: Number(),
      scopedCO2: {
        scope1: Number(),
        scope2: Number(),
        scope3: Number(),
      },
    },
    euro5Emissions: {
      CO: Number(),
      VOC: Number(),
      NOx: Number(),
      SOx: Number(),
      PM10: Number(),
      CO2: Number(),
      scopedCO2: {
        scope1: Number(),
        scope2: Number(),
        scope3: Number(),
      },
    },
    euro6Emissions: {
      CO: Number(),
      VOC: Number(),
      NOx: Number(),
      SOx: Number(),
      PM10: Number(),
      CO2: Number(),
      scopedCO2: {
        scope1: Number(),
        scope2: Number(),
        scope3: Number(),
      },
    },
    totals: {
      CO: Number(),
      VOC: Number(),
      NOx: Number(),
      SOx: Number(),
      PM10: Number(),
      CO2: Number(),
      scopedCO2: {
        scope1: Number(),
        scope2: Number(),
        scope3: Number(),
      },
    },
  },
  ldvDiesel: {
    preEuroEmissions: {
      CO: Number(),
      VOC: Number(),
      NOx: Number(),
      SOx: Number(),
      PM10: Number(),
      CO2: Number(),
      scopedCO2: {
        scope1: Number(),
        scope2: Number(),
        scope3: Number(),
      },
    },
    euro1Emissions: {
      CO: Number(),
      VOC: Number(),
      NOx: Number(),
      SOx: Number(),
      PM10: Number(),
      CO2: Number(),
      scopedCO2: {
        scope1: Number(),
        scope2: Number(),
        scope3: Number(),
      },
    },
    euro2Emissions: {
      CO: Number(),
      VOC: Number(),
      NOx: Number(),
      SOx: Number(),
      PM10: Number(),
      CO2: Number(),
      scopedCO2: {
        scope1: Number(),
        scope2: Number(),
        scope3: Number(),
      },
    },
    euro3Emissions: {
      CO: Number(),
      VOC: Number(),
      NOx: Number(),
      SOx: Number(),
      PM10: Number(),
      CO2: Number(),
      scopedCO2: {
        scope1: Number(),
        scope2: Number(),
        scope3: Number(),
      },
    },
    euro4Emissions: {
      CO: Number(),
      VOC: Number(),
      NOx: Number(),
      SOx: Number(),
      PM10: Number(),
      CO2: Number(),
      scopedCO2: {
        scope1: Number(),
        scope2: Number(),
        scope3: Number(),
      },
    },
    euro5Emissions: {
      CO: Number(),
      VOC: Number(),
      NOx: Number(),
      SOx: Number(),
      PM10: Number(),
      CO2: Number(),
      scopedCO2: {
        scope1: Number(),
        scope2: Number(),
        scope3: Number(),
      },
    },
    euro6Emissions: {
      CO: Number(),
      VOC: Number(),
      NOx: Number(),
      SOx: Number(),
      PM10: Number(),
      CO2: Number(),
      scopedCO2: {
        scope1: Number(),
        scope2: Number(),
        scope3: Number(),
      },
    },
    totals: {
      CO: Number(),
      VOC: Number(),
      NOx: Number(),
      SOx: Number(),
      PM10: Number(),
      CO2: Number(),
      scopedCO2: {
        scope1: Number(),
        scope2: Number(),
        scope3: Number(),
      },
    },
  },
  mdvDiesel: {
    preEuroEmissions: {
      CO: Number(),
      VOC: Number(),
      NOx: Number(),
      SOx: Number(),
      PM10: Number(),
      CO2: Number(),
      scopedCO2: {
        scope1: Number(),
        scope2: Number(),
        scope3: Number(),
      },
    },
    euro1Emissions: {
      CO: Number(),
      VOC: Number(),
      NOx: Number(),
      SOx: Number(),
      PM10: Number(),
      CO2: Number(),
      scopedCO2: {
        scope1: Number(),
        scope2: Number(),
        scope3: Number(),
      },
    },
    euro2Emissions: {
      CO: Number(),
      VOC: Number(),
      NOx: Number(),
      SOx: Number(),
      PM10: Number(),
      CO2: Number(),
      scopedCO2: {
        scope1: Number(),
        scope2: Number(),
        scope3: Number(),
      },
    },
    euro3Emissions: {
      CO: Number(),
      VOC: Number(),
      NOx: Number(),
      SOx: Number(),
      PM10: Number(),
      CO2: Number(),
      scopedCO2: {
        scope1: Number(),
        scope2: Number(),
        scope3: Number(),
      },
    },
    euro4Emissions: {
      CO: Number(),
      VOC: Number(),
      NOx: Number(),
      SOx: Number(),
      PM10: Number(),
      CO2: Number(),
      scopedCO2: {
        scope1: Number(),
        scope2: Number(),
        scope3: Number(),
      },
    },
    euro5Emissions: {
      CO: Number(),
      VOC: Number(),
      NOx: Number(),
      SOx: Number(),
      PM10: Number(),
      CO2: Number(),
      scopedCO2: {
        scope1: Number(),
        scope2: Number(),
        scope3: Number(),
      },
    },
    euro6Emissions: {
      CO: Number(),
      VOC: Number(),
      NOx: Number(),
      SOx: Number(),
      PM10: Number(),
      CO2: Number(),
      scopedCO2: {
        scope1: Number(),
        scope2: Number(),
        scope3: Number(),
      },
    },
    totals: {
      CO: Number(),
      VOC: Number(),
      NOx: Number(),
      SOx: Number(),
      PM10: Number(),
      CO2: Number(),
      scopedCO2: {
        scope1: Number(),
        scope2: Number(),
        scope3: Number(),
      },
    },
  },
  hdvDiesel: {
    preEuroEmissions: {
      CO: Number(),
      VOC: Number(),
      NOx: Number(),
      SOx: Number(),
      PM10: Number(),
      CO2: Number(),
      scopedCO2: {
        scope1: Number(),
        scope2: Number(),
        scope3: Number(),
      },
    },
    euro1Emissions: {
      CO: Number(),
      VOC: Number(),
      NOx: Number(),
      SOx: Number(),
      PM10: Number(),
      CO2: Number(),
      scopedCO2: {
        scope1: Number(),
        scope2: Number(),
        scope3: Number(),
      },
    },
    euro2Emissions: {
      CO: Number(),
      VOC: Number(),
      NOx: Number(),
      SOx: Number(),
      PM10: Number(),
      CO2: Number(),
      scopedCO2: {
        scope1: Number(),
        scope2: Number(),
        scope3: Number(),
      },
    },
    euro3Emissions: {
      CO: Number(),
      VOC: Number(),
      NOx: Number(),
      SOx: Number(),
      PM10: Number(),
      CO2: Number(),
      scopedCO2: {
        scope1: Number(),
        scope2: Number(),
        scope3: Number(),
      },
    },
    euro4Emissions: {
      CO: Number(),
      VOC: Number(),
      NOx: Number(),
      SOx: Number(),
      PM10: Number(),
      CO2: Number(),
      scopedCO2: {
        scope1: Number(),
        scope2: Number(),
        scope3: Number(),
      },
    },
    euro5Emissions: {
      CO: Number(),
      VOC: Number(),
      NOx: Number(),
      SOx: Number(),
      PM10: Number(),
      CO2: Number(),
      scopedCO2: {
        scope1: Number(),
        scope2: Number(),
        scope3: Number(),
      },
    },
    euro6Emissions: {
      CO: Number(),
      VOC: Number(),
      NOx: Number(),
      SOx: Number(),
      PM10: Number(),
      CO2: Number(),
      scopedCO2: {
        scope1: Number(),
        scope2: Number(),
        scope3: Number(),
      },
    },
    totals: {
      CO: Number(),
      VOC: Number(),
      NOx: Number(),
      SOx: Number(),
      PM10: Number(),
      CO2: Number(),
      scopedCO2: {
        scope1: Number(),
        scope2: Number(),
        scope3: Number(),
      },
    },
  },
  motorcycles: {
    twoStrokeEmissions: {
      CO: Number(),
      VOC: Number(),
      NOx: Number(),
      SOx: Number(),
      PM10: Number(),
      CO2: Number(),
      scopedCO2: {
        scope1: Number(),
        scope2: Number(),
        scope3: Number(),
      },
    },
    fourStrokeEmissions: {
      CO: Number(),
      VOC: Number(),
      NOx: Number(),
      SOx: Number(),
      PM10: Number(),
      CO2: Number(),
      scopedCO2: {
        scope1: Number(),
        scope2: Number(),
        scope3: Number(),
      },
    },
    eBikeEmissions: {
      CO: Number(),
      VOC: Number(),
      NOx: Number(),
      SOx: Number(),
      PM10: Number(),
      CO2: Number(),
      scopedCO2: {
        scope1: Number(),
        scope2: Number(),
        scope3: Number(),
      },
    },
    totals: {
      CO: Number(),
      VOC: Number(),
      NOx: Number(),
      SOx: Number(),
      PM10: Number(),
      CO2: Number(),
      scopedCO2: {
        scope1: Number(),
        scope2: Number(),
        scope3: Number(),
      },
    },
  },
  generators: {
    CO: Number(),
    VOC: Number(),
    NOx: Number(),
    SOx: Number(),
    PM10: Number(),
    CO2: Number(),
    scopedCO2: {
      scope1: Number(),
      scope2: Number(),
      scope3: Number(),
    },
  },
  waste: {
    scopedCO2: {
      scope1: Number(),
      scope2: Number(),
      scope3: Number(),
    },
  },
  cargoTransport: {
    CO2: Number(),
  },
  totals: {
    CO: Number(),
    VOC: Number(),
    NOx: Number(),
    SOx: Number(),
    PM10: Number(),
    CO2: Number(),
    scopedCO2: {
      scope1: Number(),
      scope2: Number(),
      scope3: Number(),
    },
  },
}

const emissionsSlice = createSlice({
  name: 'emissions',
  initialState,
  reducers: {
    setCurrentEmissionsStep: (state, { payload }) => {
      state.currentStep = payload
    },
    setPassengerCarsGasoline: (state, { payload }) => {
      state.passengerCarsGasoline = payload
    },
    setPassengerCarsDiesel: (state, { payload }) => {
      state.passengerCarsDiesel = payload
    },
    setPassengerCarsElectric: (state, { payload }) => {
      state.passengerCarsElectric = payload
    },
    setPassengerSUVsGasoline: (state, { payload }) => {
      state.passengerSUVsGasoline = payload
    },
    setPassengerSUVsDiesel: (state, { payload }) => {
      state.passengerSUVsDiesel = payload
    },
    setPassengerSUVsElectric: (state, { payload }) => {
      state.passengerSUVsElectric = payload
    },
    setLdvGasoline: (state, { payload }) => {
      state.ldvGasoline = payload
    },
    setLdvDiesel: (state, { payload }) => {
      state.ldvDiesel = payload
    },
    setMdvDiesel: (state, { payload }) => {
      state.mdvDiesel = payload
    },
    setHdvDiesel: (state, { payload }) => {
      state.hdvDiesel = payload
    },
    setMotorcycles: (state, { payload }) => {
      state.motorcycles = payload
    },
    setGenerators: (state, { payload }) => {
      state.generators = payload
    },
    setWastes: (state, { payload }) => {
      state.waste = payload
    },
    setCargo: (state, { payload }) => {
      state.cargoTransport = payload
    },
    setFleetwideEmissions: (state, { payload }) => {
      state.fleetwideEmissions = payload?.fleetWide
      state.totals.scopedCO2.scope3 = Number(state?.totals?.scopedCO2?.scope3) // + Number(payload?.fleetWide)
    },
    setTotalEmissions: (state, { payload }) => {
      // console.log(payload)
      state.totals = payload
    },
    resetEmissions: () => initialState,
  },
})

export const {
  setCurrentEmissionsStep,
  setPassengerCarsGasoline,
  setPassengerCarsDiesel,
  setPassengerCarsElectric,
  setPassengerSUVsGasoline,
  setPassengerSUVsDiesel,
  setPassengerSUVsElectric,
  setLdvGasoline,
  setLdvDiesel,
  setMdvDiesel,
  setHdvDiesel,
  setMotorcycles,
  setGenerators,
  setWastes,
  setCargo,
  setFleetwideEmissions,
  setTotalEmissions,
  resetEmissions,
} = emissionsSlice.actions
export default emissionsSlice.reducer
