import i18n from 'i18n'
import { store } from '../../index'

i18n.loadNamespaces('common')

const menuData = {
  en: [
    // VB:REPLACE-START:MENU-CONFIG
    {
      title: i18n.t('pageTitles.calc', { lng: 'en' }),
      key: 'emissionsCalc',
      url: '/home',
      icon: 'icon ivycon-calculator',
    },
    {
      title: i18n.t('pageTitles.air', { lng: 'en' }),
      key: 'airPollution',
      url: '/air-pollution-and-transport',
      icon: 'icon ivycon-car-polluting',
    },
    {
      title: i18n.t('pageTitles.tech', { lng: 'en' }),
      key: 'techInfluence',
      url: '/influence-of-technology-on-emissions',
      icon: 'icon ivycon-vehicle-tech',
    },
    {
      title: i18n.t('pageTitles.health', { lng: 'en' }),
      key: 'healthImpacts',
      url: '/health-impacts',
      icon: 'icon ivycon-heartbeat',
    },
    {
      title: i18n.t('pageTitles.env', { lng: 'en' }),
      key: 'envImpacts',
      url: '/environmental-impacts',
      icon: 'icon ivycon-climate-change',
    },
    {
      title: i18n.t('pageTitles.climate', { lng: 'en' }),
      key: 'climateChange',
      url: '/climate-change-and-the-greenhouse-effect',
      icon: 'icon ivycon-global-warming',
    },
    {
      title: i18n.t('pageTitles.journey', { lng: 'en' }),
      key: 'journeyMgmt',
      url: '/journey-management',
      icon: 'icon ivycon-journey',
    },
    {
      title: i18n.t('pageTitles.eco', { lng: 'en' }),
      key: 'ecoDriving',
      url: '/eco-driving',
      icon: 'icon ivycon-eco-driving-1',
    },
    {
      title: i18n.t('pageTitles.maint', { lng: 'en' }),
      key: 'maintenance',
      url: '/maintenance',
      icon: 'icon ivycon-maintenance',
    },
    {
      title: i18n.t('pageTitles.fuels', { lng: 'en' }),
      key: 'fuels',
      url: '/fuels',
      icon: 'icon ivycon-fuel',
    },
    {
      title: i18n.t('pageTitles.diesel', { lng: 'en' }),
      key: 'dieselEmissionsControl',
      url: '/emission-control-for-diesel-vehicles',
      icon: 'icon ivycon-diesel-particulate-filter',
    },
    {
      title: i18n.t('pageTitles.petrol', { lng: 'en' }),
      key: 'petrolEmissionsControl',
      url: '/emission-control-for-petrol-vehicles',
      icon: 'icon ivycon-catalytic-converter',
    },
    {
      title: i18n.t('pageTitles.cleanv', { lng: 'en' }),
      key: 'cleanVeh',
      url: '/cleaner-vehicles',
      icon: 'icon ivycon-clean-truck',
    },
    {
      title: i18n.t('pageTitles.evs', { lng: 'en' }),
      key: 'elecVeh',
      url: '/electric-vehicles',
      icon: 'icon ivycon-electric-vehicle',
    },
    {
      title: i18n.t('pageTitles.genalt', { lng: 'en' }),
      key: 'generatorAlt',
      url: '/generator-alternatives',
      icon: 'icon ivycon-generator-alternatives',
    },
    {
      title: i18n.t('pageTitles.cases', { lng: 'en' }),
      key: 'caseStudies',
      url: '/case-studies',
      icon: 'icon ivycon-content-14',
      children: [
        {
          title: i18n.t('pageTitles.cng', { lng: 'en' }),
          key: 'cngElec',
          url: '/case-studies/cng-vs-electricity',
        },
        {
          title: i18n.t('pageTitles.dhl', { lng: 'en' }),
          key: 'dhl',
          url: '/case-studies/dhl',
        },
        {
          title: i18n.t('pageTitles.fedex', { lng: 'en' }),
          key: 'fedex',
          url: '/case-studies/fedex',
        },
        {
          title: i18n.t('pageTitles.jarkata', { lng: 'en' }),
          key: 'jarkata',
          url: '/case-studies/jarkata',
        },
        {
          title: i18n.t('pageTitles.tnt', { lng: 'en' }),
          key: 'tnt',
          url: '/case-studies/tnt',
        },
        {
          title: i18n.t('pageTitles.unama', { lng: 'en' }),
          key: 'unama',
          url: '/case-studies/unama',
        },
        {
          title: i18n.t('pageTitles.unhcr', { lng: 'en' }),
          key: 'unhcr',
          url: '/case-studies/unhcr',
        },
        {
          title: i18n.t('pageTitles.unicef', { lng: 'en' }),
          key: 'unicef',
          url: '/case-studies/unicef',
        },
        {
          title: i18n.t('pageTitles.ups', { lng: 'en' }),
          key: 'ups',
          url: '/case-studies/ups',
        },
        {
          title: i18n.t('pageTitles.wfp', { lng: 'en' }),
          key: 'wfp',
          url: '/case-studies/wfp',
        },
      ],
    },
    {
      title: i18n.t('pageTitles.strategy', { lng: 'en' }),
      key: 'checklist',
      url: '/strategy-checklist',
      icon: 'icon ivycon-checklist',
    },
  ],
  fr: [
    // VB:REPLACE-START:MENU-CONFIG
    {
      title: i18n.t('pageTitles.calc', { lng: 'fr' }),
      key: 'emissionsCalc',
      url: '/home',
      icon: 'icon ivycon-calculator',
    },
    {
      title: i18n.t('pageTitles.air', { lng: 'fr' }),
      key: 'airPollution',
      url: '/air-pollution-and-transport',
      icon: 'icon ivycon-car-polluting',
    },
    {
      title: i18n.t('pageTitles.tech', { lng: 'fr' }),
      key: 'techInfluence',
      url: '/influence-of-technology-on-emissions',
      icon: 'icon ivycon-vehicle-tech',
    },
    {
      title: i18n.t('pageTitles.health', { lng: 'fr' }),
      key: 'healthImpacts',
      url: '/health-impacts',
      icon: 'icon ivycon-heartbeat',
    },
    {
      title: i18n.t('pageTitles.env', { lng: 'fr' }),
      key: 'envImpacts',
      url: '/environmental-impacts',
      icon: 'icon ivycon-climate-change',
    },
    {
      title: i18n.t('pageTitles.climate', { lng: 'fr' }),
      key: 'climateChange',
      url: '/climate-change-and-the-greenhouse-effect',
      icon: 'icon ivycon-global-warming',
    },
    {
      title: i18n.t('pageTitles.journey', { lng: 'fr' }),
      key: 'journeyMgmt',
      url: '/journey-management',
      icon: 'icon ivycon-journey',
    },
    {
      title: i18n.t('pageTitles.eco', { lng: 'fr' }),
      key: 'ecoDriving',
      url: '/eco-driving',
      icon: 'icon ivycon-eco-driving-1',
    },
    {
      title: i18n.t('pageTitles.maint', { lng: 'fr' }),
      key: 'maintenance',
      url: '/maintenance',
      icon: 'icon ivycon-maintenance',
    },
    {
      title: i18n.t('pageTitles.fuels', { lng: 'fr' }),
      key: 'fuels',
      url: '/fuels',
      icon: 'icon ivycon-fuel',
    },
    {
      title: i18n.t('pageTitles.diesel', { lng: 'fr' }),
      key: 'dieselEmissionsControl',
      url: '/emission-control-for-diesel-vehicles',
      icon: 'icon ivycon-diesel-particulate-filter',
    },
    {
      title: i18n.t('pageTitles.petrol', { lng: 'fr' }),
      key: 'petrolEmissionsControl',
      url: '/emission-control-for-petrol-vehicles',
      icon: 'icon ivycon-catalytic-converter',
    },
    {
      title: i18n.t('pageTitles.cleanv', { lng: 'fr' }),
      key: 'cleanVeh',
      url: '/cleaner-vehicles',
      icon: 'icon ivycon-clean-truck',
    },
    {
      title: i18n.t('pageTitles.evs', { lng: 'fr' }),
      key: 'elecVeh',
      url: '/electric-vehicles',
      icon: 'icon ivycon-electric-vehicle',
    },
    {
      title: i18n.t('pageTitles.genalt', { lng: 'fr' }),
      key: 'generatorAlt',
      url: '/generator-alternatives',
      icon: 'icon ivycon-generator-alternatives',
    },
    {
      title: i18n.t('pageTitles.cases', { lng: 'fr' }),
      key: 'caseStudies',
      url: '/case-studies',
      icon: 'icon ivycon-content-14',
      children: [
        {
          title: i18n.t('pageTitles.cng', { lng: 'fr' }),
          key: 'cngElec',
          url: '/case-studies/cng-vs-electricity',
        },
        {
          title: i18n.t('pageTitles.dhl', { lng: 'fr' }),
          key: 'dhl',
          url: '/case-studies/dhl',
        },
        {
          title: i18n.t('pageTitles.fedex', { lng: 'fr' }),
          key: 'fedex',
          url: '/case-studies/fedex',
        },
        {
          title: i18n.t('pageTitles.jarkata', { lng: 'fr' }),
          key: 'jarkata',
          url: '/case-studies/jarkata',
        },
        {
          title: i18n.t('pageTitles.tnt', { lng: 'fr' }),
          key: 'tnt',
          url: '/case-studies/tnt',
        },
        {
          title: i18n.t('pageTitles.unama', { lng: 'fr' }),
          key: 'unama',
          url: '/case-studies/unama',
        },
        {
          title: i18n.t('pageTitles.unhcr', { lng: 'fr' }),
          key: 'unhcr',
          url: '/case-studies/unhcr',
        },
        {
          title: i18n.t('pageTitles.unicef', { lng: 'fr' }),
          key: 'unicef',
          url: '/case-studies/unicef',
        },
        {
          title: i18n.t('pageTitles.ups', { lng: 'fr' }),
          key: 'ups',
          url: '/case-studies/ups',
        },
        {
          title: i18n.t('pageTitles.wfp', { lng: 'fr' }),
          key: 'wfp',
          url: '/case-studies/wfp',
        },
      ],
    },
    {
      title: i18n.t('pageTitles.strategy', { lng: 'fr' }),
      key: 'checklist',
      url: '/strategy-checklist',
      icon: 'icon ivycon-checklist',
    },
  ],
}

export default async function getMenuData(lang) {
  const state = store.getState()
  return menuData[lang || state.settings.locale]
}
