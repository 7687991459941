import React from 'react'
import { Dropdown, Tooltip } from 'antd'
import List from './List'
import styles from './style.module.scss'

const Glossary = ({ trans }) => {
  const items = [
    {
      key: '1',
      label: (
        <React.Fragment>
          <div className="card vb__utils__shadow width-350 border-0">
            <div className="card-body p-0">
              <List trans={trans} />
            </div>
          </div>
        </React.Fragment>
      ),
    },
  ]

  return (
    <Tooltip placement="topRight" title={trans('glossary')}>
      <Dropdown
        menu={{
          items,
        }}
        trigger={['click']}
        placement="bottomLeft"
      >
        <div className={styles.dropdown}>
          <i className={`${styles.icon} fa fa-book-open`} />
        </div>
      </Dropdown>
    </Tooltip>
  )
}

export default Glossary
