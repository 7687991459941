// import React, { useState, useEffect, useCallback, useMemo } from 'react'
// import { connect } from 'react-redux'
// import { withRouter } from 'react-router-dom'
// import { Menu, Layout } from 'antd'
// import classNames from 'classnames'
// import store from 'store'
// import PerfectScrollbar from 'react-perfect-scrollbar'
// import { find } from 'lodash'
// import { push } from 'connected-react-router'
// import style from './style.module.scss'

// const mapStateToProps = ({ menu, settings, user }) => ({
//   menuData: menu.menuData,
//   isMenuCollapsed: settings.isMenuCollapsed,
//   isMobileView: settings.isMobileView,
//   isMenuUnfixed: settings.isMenuUnfixed,
//   isMenuShadow: settings.isMenuShadow,
//   leftMenuWidth: settings.leftMenuWidth,
//   menuColor: settings.menuColor,
//   logo: settings.logo,
//   // version: settings.version,
//   role: user.role,
// })

// const MenuLeft = ({
//   dispatch,
//   menuData = [],
//   location: { pathname },
//   isMenuCollapsed,
//   isMobileView,
//   isMenuUnfixed,
//   isMenuShadow,
//   leftMenuWidth,
//   menuColor,
//   logo,
//   // version,
//   role,
// }) => {
//   const [selectedKeys, setSelectedKeys] = useState(store.get('app.menu.selectedKeys') || [])
//   const [openedKeys, setOpenedKeys] = useState(store.get('app.menu.openedKeys') || [])

//   const flattenItems = useCallback(
//     (items, key) =>
//       items.reduce((flattenedItems, item) => {
//         flattenedItems.push(item)
//         if (Array.isArray(item[key])) {
//           return flattenedItems.concat(flattenItems(item[key], key))
//         }
//         return flattenedItems
//       }, []),
//     [],
//   )

//   const applySelectedKeys = useCallback(() => {
//     const selectedItem = find(flattenItems(menuData, 'items'), ['url', pathname])
//     setSelectedKeys(selectedItem ? [selectedItem.key] : [])
//   }, [pathname, menuData, flattenItems])

//   useEffect(() => {
//     applySelectedKeys()
//   }, [pathname, menuData, applySelectedKeys])

//   const onCollapse = useCallback(
//     (value, type) => {
//       if (type === 'responsive' && isMenuCollapsed) {
//         return
//       }
//       dispatch({
//         type: 'settings/CHANGE_SETTING',
//         payload: {
//           setting: 'isMenuCollapsed',
//           value: !isMenuCollapsed,
//         },
//       })
//       setOpenedKeys([])
//     },
//     [isMenuCollapsed, dispatch],
//   )

//   const onOpenChange = useCallback((keys) => {
//     if (store.get('app.menu.openedKeys') !== keys) {
//       store.set('app.menu.openedKeys', keys)
//     }
//     setOpenedKeys(keys)
//   }, [])

//   const handleClick = useCallback(
//     (e) => {
//       const keyToUpdate = [e.key]
//       if (store.get('app.menu.selectedKeys') !== keyToUpdate) {
//         store.set('app.menu.selectedKeys', keyToUpdate)
//       }
//       setSelectedKeys(keyToUpdate)
//       const clickedItem = menuData.find((item) => item.key === e.key)
//       if (clickedItem) {
//         const { url } = clickedItem
//         dispatch(push(url))
//       }
//     },
//     [menuData, dispatch],
//   )

//   const generatedMenuItems = useMemo(() => {
//     const generateItem = (item) => {
//       const { key, title, url, icon, disabled } = item
//       return {
//         key,
//         title,
//         disabled,
//         url,
//         label: <span className={style.title}>{title}</span>,
//         icon: icon && <span className={`${icon} ${style.icon} icon-collapsed-hidden`} />,
//       }
//     }

//     const generateSubmenu = (items) =>
//       items.map((menuItem) => {
//         if (menuItem.items) {
//           const subMenuTitle = (
//             <span key={menuItem.key}>
//               <span className={style.title}>{menuItem.title}</span>
//               {menuItem.count && <span className="badge badge-success ml-2">{menuItem.count}</span>}
//               {menuItem.icon && <span className={`${menuItem.icon} ${style.icon}`} />}
//             </span>
//           )
//           return (
//             <Menu.SubMenu title={subMenuTitle} key={menuItem.key}>
//               {generateSubmenu(menuItem.items)}
//             </Menu.SubMenu>
//           )
//         }
//         return generateItem(menuItem)
//       })

//     return menuData.map((menuItem) => {
//       if (menuItem.roles && !menuItem.roles.includes(role)) {
//         return null
//       }
//       if (menuItem.items) {
//         const subMenuTitle = (
//           <span key={menuItem.key}>
//             <span className={style.title}>{menuItem.title}</span>
//             {menuItem.count && <span className="badge badge-success ml-2">{menuItem.count}</span>}
//             {menuItem.icon && <span className={`${menuItem.icon} ${style.icon}`} />}
//           </span>
//         )
//         return (
//           <Menu.SubMenu title={subMenuTitle} key={menuItem.key}>
//             {generateSubmenu(menuItem.items)}
//           </Menu.SubMenu>
//         )
//       }
//       return generateItem(menuItem)
//     })
//   }, [menuData, role])

//   return (
//     <Layout.Sider
//       width={leftMenuWidth}
//       className={classNames(style.menu, {
//         [style.whiteTheme]: menuColor === 'white',
//         [style.darkTheme]: menuColor !== 'white',
//         [style.unfixed]: isMenuUnfixed,
//         [style.shadow]: isMenuShadow,
//       })}
//       collapsible
//       collapsed={isMenuCollapsed}
//       onCollapse={onCollapse}
//       breakpoint="lg"
//     >
//       <div className={style.logoContainer}>
//         <div className={style.logo}>
//           <svg
//             xmlns="http://www.w3.org/2000/svg"
//             viewBox="0 0 24 24"
//             version="1.1"
//             height="24px"
//             width="24px"
//           >
//             <g>
//               <path
//                 fill="#B9C08B"
//                 strokeWidth="1"
//                 stroke="#B9C08B"
//                 d="M12,10.9c-0.1,0-0.2,0-0.2-0.1L3.5,6.1C3.4,6,3.3,5.8,3.3,5.6c0-0.2,0.1-0.3,0.2-0.4l8.2-4.7c0.2-0.1,0.3-0.1,0.5,0      l8.2,4.7c0.2,0.1,0.2,0.3,0.2,0.4S20.6,6,20.5,6.1l-8.2,4.7C12.2,10.8,12.1,10.9,12,10.9z M4.8,5.6L12,9.8l7.2-4.2L12,1.5      L4.8,5.6z"
//               />
//               <path
//                 fill="#B9C08B"
//                 strokeWidth="1"
//                 stroke="#B9C08B"
//                 d="M13.6,23.6c-0.1,0-0.2,0-0.2-0.1c-0.2-0.1-0.2-0.3-0.2-0.4v-9.5c0-0.2,0.1-0.3,0.2-0.4l8.2-4.7c0.2-0.1,0.3-0.1,0.5,0      c0.2,0.1,0.2,0.3,0.2,0.4v9.5c0,0.2-0.1,0.3-0.3,0.4l-8.2,4.7C13.8,23.6,13.7,23.6,13.6,23.6z M14.1,13.9v8.3l7.2-4.2V9.8      L14.1,13.9z"
//               />
//               <path
//                 fill="#B9C08B"
//                 strokeWidth="1"
//                 stroke="#B9C08B"
//                 d="M10.4,23.6c-0.1,0-0.2,0-0.2-0.1l-8.2-4.7c-0.2-0.1-0.3-0.3-0.3-0.4V8.9c0-0.2,0.1-0.3,0.2-0.4c0.2-0.1,0.3-0.1,0.5,0      l8.2,4.7c0.2,0.1,0.2,0.3,0.2,0.4v9.5c0,0.2-0.1,0.3-0.2,0.4C10.5,23.6,10.5,23.6,10.4,23.6z M2.7,18.1l7.2,4.2v-8.3L2.7,9.8      V18.1z"
//               />
//             </g>
//           </svg>
//           <div className={style.name}>{logo}</div>
//           {/* <div className={style.name}>{version}</div> */}
//           {isMenuCollapsed && !isMobileView && <div className={style.mobileToggleButton} />}
//         </div>
//       </div>
//       <PerfectScrollbar className={style.scrollbar}>
//         <Menu
//           onClick={handleClick}
//           selectedKeys={selectedKeys}
//           openKeys={openedKeys}
//           onOpenChange={onOpenChange}
//           mode="inline"
//           className={style.navigation}
//         >
//           {generatedMenuItems}
//         </Menu>
//       </PerfectScrollbar>
//     </Layout.Sider>
//   )
// }

// export default withRouter(connect(mapStateToProps)(MenuLeft))

import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import {
  // Link,
  withRouter,
} from 'react-router-dom'
import { Menu, Layout } from 'antd'
import classNames from 'classnames'
import store from 'store'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { find } from 'lodash'
import { push } from 'connected-react-router'
import style from './style.module.scss'

const mapStateToProps = ({ menu, settings, user }) => ({
  menuData: menu.menuData,
  isMenuCollapsed: settings.isMenuCollapsed,
  isMobileView: settings.isMobileView,
  isMenuUnfixed: settings.isMenuUnfixed,
  isMenuShadow: settings.isMenuShadow,
  leftMenuWidth: settings.leftMenuWidth,
  menuColor: settings.menuColor,
  logo: settings.logo,
  version: settings.version,
  role: user.role,
})

const MenuLeft = ({
  dispatch,
  menuData = [],
  location: { pathname },
  isMenuCollapsed,
  isMobileView,
  isMenuUnfixed,
  isMenuShadow,
  leftMenuWidth,
  menuColor,
  logo,
  version,
  role,
}) => {
  const [selectedKeys, setSelectedKeys] = useState(store.get('app.menu.selectedKeys') || [])
  const [openedKeys, setOpenedKeys] = useState(store.get('app.menu.openedKeys') || [])

  useEffect(() => {
    applySelectedKeys()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, menuData])

  const applySelectedKeys = () => {
    const flattenItems = (items, key) =>
      items.reduce((flattenedItems, item) => {
        flattenedItems.push(item)
        if (Array.isArray(item[key])) {
          return flattenedItems.concat(flattenItems(item[key], key))
        }
        return flattenedItems
      }, [])
    const selectedItem = find(flattenItems(menuData, 'items'), ['url', pathname])
    setSelectedKeys(selectedItem ? [selectedItem.key] : [])
  }

  const onCollapse = (value, type) => {
    if (type === 'responsive' && isMenuCollapsed) {
      return
    }
    dispatch({
      type: 'settings/CHANGE_SETTING',
      payload: {
        setting: 'isMenuCollapsed',
        value: !isMenuCollapsed,
      },
    })
    setOpenedKeys([])
  }

  const onOpenChange = (keys) => {
    store.set('app.menu.openedKeys', keys)
    setOpenedKeys(keys)
  }

  const handleClick = (e) => {
    // console.log('menu clicked:', e)
    store.set('app.menu.selectedKeys', [e.key])
    // Update selectedKeys
    // dispatch({ type: 'UPDATE_SELECTED_KEYS', payload: [e.key] })
    setSelectedKeys([e.key])
    const clickedItem = menuData.find((item) => item.key === e.key)
    if (clickedItem) {
      const { url } = clickedItem
      // Dispatch action to update route and store
      dispatch(push(url))
    }
  }

  const generateMenuItems = () => {
    // const menuItems = []
    const generateItem = (item) => {
      const { key, title, url, icon, disabled } = item
      // if (item.category) {
      //   const menuItem = {
      //     key: Math.random(),
      //     label: item.title,
      //   }
      //   menuItems.push(menuItem)
      //   // return <Menu.ItemGroup key={Math.random()} title={item.title} />
      // }
      // if (item?.url && item.target) {
      //   // const externalLinks = { // Not required in CFT use case
      //   //   key,
      //   //   disabled,
      //   //   label: (
      //   //     <a href={url} target={item.target} rel="noopener noreferrer">
      //   //       <span className={style.title}>{title}</span>
      //   //       {count && <span className="badge badge-success ml-2">{count}</span>}
      //   //       {icon && <span className={`${icon} ${style.icon} icon-collapsed-hidden`} />}
      //   //     </a>
      //   //   )
      //   // };

      //   const internalLinks = {
      //     key,
      //     disabled,
      //     label: (
      //       <Link to={url}>
      //         <span className={style.title}>{title}</span>
      //         {count && <span className="badge badge-success ml-2">{count}</span>}
      //         {icon && <span className={`${icon} ${style.icon} icon-collapsed-hidden`} />}
      //       </Link>
      //     ),
      //   }

      //   menuItems.push(internalLinks)

      //   return (
      //     <Menu.Item key={key} disabled={disabled}>
      //       {item.target && (
      //         <a href={url} target={item.target} rel="noopener noreferrer">
      //           <span className={style.title}>{title}</span>
      //           {count && <span className="badge badge-success ml-2">{count}</span>}
      //           {icon && <span className={`${icon} ${style.icon} icon-collapsed-hidden`} />}
      //         </a>
      //       )}
      //       {!item.target && (
      //         <Link to={url}>
      //           <span className={style.title}>{title}</span>
      //           {count && <span className="badge badge-success ml-2">{count}</span>}
      //           {icon && <span className={`${icon} ${style.icon} icon-collapsed-hidden`} />}
      //         </Link>
      //       )}
      //     </Menu.Item>
      //   )
      // }
      // return (
      //   <Menu.Item key={key} disabled={disabled} about={url}>
      //     <span className={style.title}>{title}</span>
      //     {count && <span className="badge badge-success ml-2">{count}</span>}
      //     {icon && <span className={`${icon} ${style.icon} icon-collapsed-hidden`} />}
      //   </Menu.Item>
      // )
      return {
        key,
        title,
        disabled,
        url,
        label: <span className={style.title}>{title}</span>,
        icon: icon && <span className={`${icon} ${style.icon} icon-collapsed-hidden`} />,
      }
    }

    const generateSubmenu = (items) =>
      items.map((menuItem) => {
        if (menuItem.items) {
          const subMenuTitle = (
            <span key={menuItem.key}>
              <span className={style.title}>{menuItem.title}</span>
              {menuItem.count && <span className="badge badge-success ml-2">{menuItem.count}</span>}
              {menuItem.icon && <span className={`${menuItem.icon} ${style.icon}`} />}
            </span>
          )
          return (
            <Menu.SubMenu title={subMenuTitle} key={menuItem.key}>
              {generateSubmenu(menuItem.items)}
            </Menu.SubMenu>
          )
        }
        return generateItem(menuItem)
      })

    return menuData.map((menuItem) => {
      if (menuItem.roles && !menuItem.roles.includes(role)) {
        return null
      }
      if (menuItem.items) {
        const subMenuTitle = (
          <span key={menuItem.key}>
            <span className={style.title}>{menuItem.title}</span>
            {menuItem.count && <span className="badge badge-success ml-2">{menuItem.count}</span>}
            {menuItem.icon && <span className={`${menuItem.icon} ${style.icon}`} />}
          </span>
        )
        return (
          <Menu.SubMenu title={subMenuTitle} key={menuItem.key}>
            {generateSubmenu(menuItem.items)}
          </Menu.SubMenu>
        )
      }
      return generateItem(menuItem)
    })
  }

  // console.log(menuData)

  // const menuData = generateMenuItems()

  const menuSettings = isMobileView
    ? {
        width: leftMenuWidth,
        collapsible: false,
        collapsed: false,
        onCollapse,
      }
    : {
        width: leftMenuWidth,
        collapsible: true,
        collapsed: isMenuCollapsed,
        onCollapse,
        breakpoint: 'lg',
      }

  return (
    <Layout.Sider
      {...menuSettings}
      className={classNames(`${style.menu}`, {
        [style.white]: menuColor === 'white',
        [style.gray]: menuColor === 'gray',
        [style.dark]: menuColor === 'dark',
        [style.unfixed]: isMenuUnfixed,
        [style.shadow]: isMenuShadow,
      })}
    >
      <div
        className={style.menuOuter}
        style={{
          width: isMenuCollapsed && !isMobileView ? 80 : leftMenuWidth,
          height: isMobileView || isMenuUnfixed ? 'calc(100% - 64px)' : 'calc(100% - 110px)',
        }}
      >
        <div className={style.logoContainer}>
          <div className={style.logo}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              version="1.1"
              height="24px"
              width="24px"
            >
              <g>
                <path
                  fill="#B9C08B"
                  strokeWidth="1"
                  stroke="#B9C08B"
                  d="M12,10.9c-0.1,0-0.2,0-0.2-0.1L3.5,6.1C3.4,6,3.3,5.8,3.3,5.6c0-0.2,0.1-0.3,0.2-0.4l8.2-4.7c0.2-0.1,0.3-0.1,0.5,0      l8.2,4.7c0.2,0.1,0.2,0.3,0.2,0.4S20.6,6,20.5,6.1l-8.2,4.7C12.2,10.8,12.1,10.9,12,10.9z M4.8,5.6L12,9.8l7.2-4.2L12,1.5      L4.8,5.6z"
                />
                <path
                  fill="#B9C08B"
                  strokeWidth="1"
                  stroke="#B9C08B"
                  d="M13.6,23.6c-0.1,0-0.2,0-0.2-0.1c-0.2-0.1-0.2-0.3-0.2-0.4v-9.5c0-0.2,0.1-0.3,0.2-0.4l8.2-4.7c0.2-0.1,0.3-0.1,0.5,0      c0.2,0.1,0.2,0.3,0.2,0.4v9.5c0,0.2-0.1,0.3-0.3,0.4l-8.2,4.7C13.8,23.6,13.7,23.6,13.6,23.6z M14.1,13.9v8.3l7.2-4.2V9.8      L14.1,13.9z"
                />
                <path
                  fill="#B9C08B"
                  strokeWidth="1"
                  stroke="#B9C08B"
                  d="M10.4,23.6c-0.1,0-0.2,0-0.2-0.1l-8.2-4.7c-0.2-0.1-0.3-0.3-0.3-0.4V8.9c0-0.2,0.1-0.3,0.2-0.4c0.2-0.1,0.3-0.1,0.5,0      l8.2,4.7c0.2,0.1,0.2,0.3,0.2,0.4v9.5c0,0.2-0.1,0.3-0.2,0.4C10.5,23.6,10.5,23.6,10.4,23.6z M2.7,18.1l7.2,4.2v-8.3L2.7,9.8      V18.1z"
                />
              </g>
            </svg>
            <div className={style.name}>{logo}</div>
            <div className={`${style.descr} text-capitalize`}>{version}</div>
          </div>
        </div>
        <PerfectScrollbar>
          <Menu
            onClick={handleClick}
            selectedKeys={selectedKeys}
            openKeys={openedKeys}
            onOpenChange={onOpenChange}
            mode="inline"
            className={style.navigation}
            inlineIndent="15"
            items={generateMenuItems()}
          />
        </PerfectScrollbar>
      </div>
    </Layout.Sider>
  )
}

export default withRouter(connect(mapStateToProps)(MenuLeft))
