import React from 'react'
import { connect } from 'react-redux'
import { Dropdown, Avatar, Divider } from 'antd'
import styles from './style.module.scss'

const mapStateToProps = ({ user, settings }) => ({
  // theme: settings.theme,
  mode: settings.menuColor,
  user,
})

const ProfileMenu = ({ dispatch, user, trans, mode }) => {
  const logout = (e) => {
    e.preventDefault()
    dispatch({
      type: 'user/SAML_LOGOUT',
    })
  }

  const login = (e) => {
    e.preventDefault()
    dispatch({
      type: 'user/SAML_LOGIN',
    })
  }

  const guestAvatar =
    // eslint-disable-next-line no-nested-ternary
    !user.authorized && mode === 'dark'
      ? 'resources/images/avatars/avatar.png'
      : !user.authorized && mode === 'white'
      ? 'resources/images/avatars/avatar-2.png'
      : 'resources/images/avatars/avatar.png'

  const items = []
  const topRow = {
    key: 'top',
    label: (
      <>
        <strong>
          {trans('hello')}, {user.name || trans('anon')}
        </strong>
        <div>
          <strong>{trans('role')}: </strong>
          {user.role || '—'}
        </div>
      </>
    ),
  }
  const orgRow = {
    key: 'org',
    label: (
      <>
        <div>
          <strong className="mr-1">{trans('org')}: </strong>
          {user.organisation || ''}
        </div>
        <div>
          <strong>{trans('email')}: </strong>
          {user.email || '-'}
        </div>
      </>
    ),
  }

  const createDividerRow = (key) => ({
    key: `div-${key}`,
    label: <Divider dashed className="my-0" />,
  });

  const profileRow = {
    key: 'profile',
    label: (
      <a href="#/profile">
        <i className="fa fa-user mr-2" />
        {trans('viewProfile')}
      </a>
    ),
  }
  const commitmentsRow = {
    key: 'commitments',
    label: (
      <a href="#/commitments">
        <i className="fa fa-object-group my-2" />
        {trans('manCommitments')}
      </a>
    ),
  }
  const logoutRow = {
    key: 'logout',
    label: (
      <a href="#" onClick={logout}>
        <i className="fa fa-right-from-bracket mr-2" />
        {trans('logout')}
      </a>
    ),
  }
  const loginRow = {
    key: 'login',
    label: (
      <a href="#" onClick={login}>
        <i className="fa fa-right-to-bracket mr-2" />
        {trans('login')}
      </a>
    ),
  }

  // construct the array
  items.push(topRow)
  if (user?.authorized) items.push(orgRow)
  items.push(createDividerRow(1))
  if (user?.authorized) {
    items.push(profileRow)
    items.push(createDividerRow(2))
    if (user?.role === 'Administrator') {
      items.push(commitmentsRow)
      items.push(createDividerRow(3))
    } else if (user?.permissions) {
      if (
        user.permissions.some((perm) => perm.split('|').includes('346')) ||
        user.permissions.some((perm) => perm.split('|').includes('348'))
      ) {
        items.push(commitmentsRow)
        items.push(createDividerRow(3))
      }
    }
    items.push(logoutRow)
  } else items.push(loginRow)

  return (
    <Dropdown menu={{ items }}>
      <div className={styles.dropdown}>
        <div className={styles.flag}>
          <Avatar
            className={styles.avatar}
            shape="square"
            size="large"
            src={<img src={user.photo || guestAvatar} alt="pic" />}
          />
        </div>
      </div>
    </Dropdown>
  )
}

export default connect(mapStateToProps)(ProfileMenu)
