/* eslint-disable no-nested-ternary */
/* eslint-disable eqeqeq */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-else-return */
import { createAsyncThunk } from '@reduxjs/toolkit'
import { message, notification } from 'antd'
import { BACKEND_URL } from 'config'
import { calculateScopedCO2 } from 'utils/calculateEmissions'

// eslint-disable-next-line import/prefer-default-export
export const getAllData = createAsyncThunk(
  'data/getAll',
  async ({ orgId, userId, isFilteredMYRecords }, { rejectWithValue }) => {
    try {
      const response = await fetch(
        `${BACKEND_URL}/commitments/all?orgId=${orgId}&userId=${userId}&isFilteredMYRecords=${isFilteredMYRecords}`,
      )
      if (response.ok) {
        const responseData = await response.json()
        // console.log("getAllData:", responseData)
        return responseData
      } else {
        message.error('Error in getting data')
        const errorData = await response.json()
        return rejectWithValue(errorData.message)
      }
    } catch (error) {
      return rejectWithValue(error.message)
    }
  },
)

export const addData = createAsyncThunk(
  'data/add',
  async ({ item, user, organization }, { rejectWithValue }) => {
    try {
      // console.log('submitted item:', item)
      const calculated =
        item.fuelType === '5d980e76f9df733088e3a725' ||
          item.fuelType.toLowerCase().trim() === 'diesel'
          ? calculateScopedCO2({
            fuelType: 'diesel',
            fuelQuantity: item.inputParameters ? item.inputParameters[0].fuel : item.fuel,
            ownedVehicles: item.numVeh,
          })
          : item.fuelType == '5d980e38f9df733088e3a724' ||
            item.fuelType.toLowerCase().trim() == 'gasoline' ||
            item.fuelType.toLowerCase().trim() == 'petrol'
            ? calculateScopedCO2({
              fuelType: 'gasoline',
              fuelQuantity: item.inputParameters ? item.inputParameters[0].fuel : item.fuel,
              ownedVehicles: item.numVeh,
            })
            : 0
      const requestBody = {
        user: {
          user_id: user.user_id,
          name: user.fullName,
        },
        year: item.year,
        organization: {
          org_id: organization.org_id,
          orgName: organization.orgName,
        },
        country: item.country,
        fuelType: item.fuelType.trim(),
        inputParameters: [
          {
            numVeh: item.numVeh,
            mileage: item.mileage,
            fuel: item.fuel,
            emissions: {
              scope1: {
                totalC02: parseFloat(calculated.scope1 ? calculated.scope1 : calculated),
                co2PerVeh: calculated.scope1
                  ? parseFloat(((calculated.scope1 || calculated) / item.numVeh).toFixed(2))
                  : 0,
                co: calculated.scope1
                  ? parseFloat(((calculated.scope1 || calculated) / item.mileage).toFixed(6))
                  : 0,
              },
            },
          },
        ],
      }

      const response = await fetch(`${BACKEND_URL}/commitments`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      })

      if (response.ok) {
        const responseData = await response.json()
        notification.success({
          message: 'Data added successfully',
        })
        return responseData
      } else {
        const errorData = await response.json()
        if (errorData.status === 409) {
          notification.error({
            message: 'A similar record already exists for your organisation!',
          })
        } else {
          notification.error({
            message: 'Failed to add the record',
            description: errorData.message,
          })
        }
        throw new Error(errorData.message)
      }
    } catch (error) {
      return rejectWithValue(error.message)
    }
  },
)

export const editData = createAsyncThunk(
  'data/edit',
  async ({ commitmentId, body, selectedValues, user }, { rejectWithValue }) => {
    try {
      const fType = body.fuelType ?? selectedValues.fuelType
      const fQty = body?.fuel ?? selectedValues.fuel
      const fMileage = body.mileage ?? selectedValues.mileage
      const fVehicleNo = body.numVeh ?? selectedValues.numVeh
      const calculated =
        fType === '5d980e76f9df733088e3a725' || fType.toLowerCase() === 'diesel'
          ? calculateScopedCO2({
            fuelType: 'diesel',
            fuelQuantity: fQty,
            ownedVehicles: fVehicleNo || 1, // pass an arbitrary value greater than 1 to ensure calculation happens
          })
          : fType == '5d980e38f9df733088e3a724' ||
            fType.toLowerCase() == 'gasoline' ||
            fType.toLowerCase() == 'petrol'
            ? calculateScopedCO2({
              fuelType: 'gasoline',
              fuelQuantity: fQty,
              ownedVehicles: fVehicleNo || 1, // pass an arbitrary value greater than 1 to ensure calculation happens
            })
            : 0

      // console.log('Calculated:', calculated.scope1)

      const response = await fetch(`${BACKEND_URL}/commitments/${commitmentId}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          numVeh: body.numVeh,
          mileage: body.mileage,
          fuel: body.fuel,
          valuesId: body.valuesId,
          year: body.year,
          country: body.country,
          fuelType: body.fuelType,
          userId: user.id,
          orgId: user.orgId,
          org: user.organisation,
          emissions: {
            scope1: {
              totalC02: parseFloat(calculated.scope1 ? calculated.scope1 : calculated),
              co2PerVeh: calculated.scope1
                ? parseFloat(((calculated.scope1 || calculated) / fVehicleNo).toFixed(2))
                : 0,
              co: calculated.scope1
                ? parseFloat(((calculated.scope1 || calculated) / fMileage).toFixed(6))
                : 0,
            },
          },
        }),
      })

      if (response.ok) {
        const responseData = await response.json()
        // message.success('Data has been updated successfully')
        notification.success({
          message: 'Data updated successfully',
        })
        return responseData
      } else {
        const errorData = await response.json()
        // console.error(errorData)
        notification.error({
          message: 'Update failed',
          description: errorData.messge,
        })
        return rejectWithValue(errorData.message)
      }
    } catch (error) {
      return rejectWithValue(error.message)
    }
  },
)

export const deleteData = createAsyncThunk(
  'data/delete',
  async ({ commitmentId, user }, { rejectWithValue }) => {
    try {
      const response = await fetch(`${BACKEND_URL}/commitments/${commitmentId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userId: user.userId,
          orgId: user.orgId,
        }),
      })

      if (response.ok) {
        const responseData = await response.json()
        // message.warn("Data deleted!")
        notification.success({
          message: 'Data deleted successfully',
        })
        return responseData
      } else {
        const errorData = await response.json()
        notification.error({
          message: 'Failed to delete the record',
          description: errorData.message,
        })
        return rejectWithValue(errorData.message)
      }
    } catch (error) {
      return rejectWithValue(error.message)
    }
  },
)

export const getAllLists = createAsyncThunk('data/getAllLists', async (_, { rejectWithValue }) => {
  try {
    const response = await fetch(`${BACKEND_URL}/api/lists`)
    if (response.ok) {
      const responseData = await response.json()
      return responseData
    } else {
      message.error('Error in retrieving fuel types')
      const errorData = await response.json()
      return rejectWithValue(errorData.message)
    }
  } catch (error) {
    return rejectWithValue(error.message)
  }
})

export const getOrganizationsEmission = createAsyncThunk(
  'data/emissions',
  async (
    { orgId, organization, year, country, fuelType, selectedOrg, selectedOwn },
    { rejectWithValue },
  ) => {
    try {
      const params = new URLSearchParams()
      params.append('orgId', orgId || '')
      params.append('orgName', organization || '')

      if (Array.isArray(fuelType)) {
        fuelType.forEach((ft) => params.append('fuelType', ft.toLowerCase()))
      } else if (fuelType) {
        params.append('fuelType', fuelType)
      }

      if (Array.isArray(year)) {
        year.forEach((y) => params.append('year', y))
      } else if (year) {
        params.append('year', year)
      }

      if (Array.isArray(country)) {
        country.forEach((c) => params.append('country', c))
      } else if (country) {
        params.append('country', country)
      }

      if (Array.isArray(selectedOrg)) {
        selectedOrg.forEach((c) => params.append('organizations', c))
      } else if (selectedOrg) {
        params.append('organizations', selectedOrg)
      }

      if (Array.isArray(selectedOwn)) {
        selectedOwn.forEach((c) => params.append('owners', c))
      } else if (selectedOwn) {
        params.append('owners', selectedOwn)
      }

      // console.log("search params:", params.toString())

      const response = await fetch(`${BACKEND_URL}/api/?${params.toString()}`)
      if (response.ok) {
        const responseData = await response.json()
        // console.log('orgEmissions:', responseData)
        return responseData
      } else {
        message.error('Error retrieving commitments data!')
        const errorData = await response.json()
        return rejectWithValue(errorData.message)
      }
    } catch (error) {
      return rejectWithValue(error.message)
    }
  },
)

export const deleteMultipleCommitments = createAsyncThunk(
  'data/delete-multiple',
  async ({ commitmentIds, user }, { rejectWithValue }) => {
    console.log(commitmentIds, user)
    try {
      const response = await fetch(`${BACKEND_URL}/delete-multiple-commitments/${user.userId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          orgId: user.orgId,
          commitmentIds
        }),
      })
      if (response.ok) {
        const responseData = await response.json()
        notification.success({
          message: 'Data deleted successfully',
        })
        return responseData
      } else {
        const errorData = await response.json()
        notification.error({
          message: 'Failed to delete the record',
          description: "An error occured while deleting commitments!",
        })
        return rejectWithValue(errorData.message)
      }
    } catch (error) {
      return rejectWithValue(error.message)
    }
  },
)
