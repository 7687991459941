import React from 'react'

const BrandingHeader = ({ trans }) => {
  return (
    <div className="d-flex brandingHeader justify-content-between">
      <div className="d-none d-md-flex">
        <img src="resources/images/logos/FleetForumLogo.png" alt="Fleet Forum Logo" />
      </div>
      <div className="align-self-center">
        <h1 className="text-primary">{trans('SITETITLE')}</h1>
      </div>
      <div className="d-none d-md-flex">
        <img src="resources/images/logos/UnEnvLogo.png" alt="UN Environment Logo" />
      </div>
    </div>
  )
}

export default BrandingHeader
