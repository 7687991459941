const translations = {
  all: {
    acidRain: {
      title: 'Acid Rain',
      desc:
        'Rainfall made so acidic by atmospheric pollution that it causes environmental harm, chiefly to forests and lakes. The main cause is the industrial burning of coal and other fossil fuels, the waste gases from which contain sulphur and nitrogen oxides which combine with atmospheric water to form acids.',
    },
    acidification: {
      title: 'Acidification',
      desc:
        'Excessive amounts of sulphuric and nitric acid are formed from sulphur and nitrogen oxide emissions into the atmosphere, and are deposited in the environment.',
    },
    biofuel: {
      title: 'Biofuel',
      desc: 'Vehicle fuel derived from biomass, i. e, grain, sugar cane, corn, wood.',
    },
    biomethanol: {
      title: 'Bioethanol',
      desc: 'Ethanol derived from biomass, usually sugar cane, corn or grain.',
    },
    biodiesel: {
      title: 'Biodiesel',
      desc: 'Diesel derived from biomass, usually rapeseed or other vegetable oils.',
    },
    cc: {
      title: 'Catalytic Converter',
      desc:
        'An exhaust emission control device that converts toxic gases and pollutants in exhaust gas from an internal combustion engine into less-toxic pollutants by catalyzing a redox reaction (an oxidation and a reduction reaction).',
    },
    co: {
      abbr: 'CO',
      title: 'Carbon monoxide (CO)',
      desc:
        'A colorless, odorless and tasteless — but toxic — gas formed in incomplete combustion. CO is extremely dangerous if inhaled at high concentrations because it has a greater affinity than oxygen for red blood cells (hemoglobin), reducing its ability to transport oxygen to vital organs.',
    },
    co2: {
      abbr: 'CO2',
      title: 'Carbon dioxide (CO2)',
      desc:
        'A colorless gas formed during the combustion of any material containing carbon and is an important greenhouse gas.',
    },
    cvd: {
      title: 'Cardiovascular diseases',
      desc: 'Diseases that affect the heart and circulatory system.',
    },
    climchange: {
      title: 'Climate change',
      desc:
        'A process whereby human activities resulting in the emission of greenhouse gas (mostly CO2 and methane) change the climate in the long term. This is also referred to as “global warming”',
    },
    cng: {
      title: 'Compressed Natural Gas (CNG)',
      desc: 'Cleaner vehicle fuel used in adapted normal combustion engines.',
    },
    dp: {
      title: 'Dispersion model',
      desc:
        'Model that describes the dispersion of vehicle emission into the surrounding environment.',
    },
    drm: {
      title: 'Dose-response model',
      desc:
        'Model that describes the relationship between a concentration of a certain pollutant and the health effects in people.',
    },
    doc: {
      title: 'Diesel Oxidation Catalyst (DOC)',
      desc:
        'Reduces PM and HC emissions from diesel vehicles. Requires diesel with less than 500 parts per million of sulphur.',
    },
    dpf: {
      title: 'Diesel Particulate Filter (DPF)',
      desc:
        'Reduces PM and HC emissions from diesel vehicles. Requires low or ultra low sulphur diesel, (50 parts per million or below).',
    },
    driveTrain: {
      title: 'Drive train',
      desc:
        'Includes the energy conversion and the power distribution system in the vehicle, or put more simply, the engine and the transmission on a normal vehicle (a mechanical drive train). An electric drive train used in a hybrid vehicle, fuel cell vehicle or in a battery electric vehicle consists of an electric motor, power electronics, power battery, and a transmission.',
    },
    eco: {
      title: 'Eco driving',
      desc:
        'Education in better driving behavior that lowers fuel consumption, emissions and accidents.',
    },
    egr: {
      title: 'Exhaust Gas Recirculation (EGR)',
      desc: 'Exhaust system that reduces the emissions of NOx from diesel vehicles.',
    },
    ev: {
      title: 'Electric Vehicle (EV)',
      desc:
        'Vehicle with an electric drive train. Can be either a purely battery powered electric vehicle, a hybrid electric vehicle (using both battery and fossil fuel combustion), or a fuel cell electric vehicle.',
    },
    ef: {
      title: 'Emission factors',
      desc:
        'The emission per km driven under normal conditions for vehicles, usually measured in g/km.',
    },
    tuneup: {
      title: 'Engine tune-up',
      desc:
        'Optimizing the engine’s performance by calibrating the ignition system, air supply system, and fuel supply system.',
    },
    euro: {
      title: 'Euro standards',
      desc:
        'European Union emission standards (in g/km). Started with Euro 1 in the early 1990s and has become progressively more stringent.',
    },
    Eutrophication: {
      title: 'Eutrophication',
      desc:
        'This is also called “nutrient pollution” and is caused partly by nitrogen oxide emissions.',
    },
    fcev: {
      title: 'Fuel Cell Electric Vehicle (FCEV)',
      desc: 'Uses a fuel cell instead of a combustion engine, see below.',
    },
    ftf: {
      title: '',
      desc: '',
    },
    fuelcell: {
      title: 'Fuel cell',
      desc:
        'Chemical engine that converts hydrogen (H2) to electricity and water with high efficiency.',
    },
    gg: {
      title: 'Greenhouse gas',
      desc: 'Gas that contributes to global warming and thus climate change.',
    },
    hev: {
      title: 'Hybrid Electric Vehicle (HEV)',
      desc:
        'Combines an electric motor with a combustion motor. The vehicle can drive on a rechargeable battery some of the time, thus lowering fuel consumption. See also PHEV (Plug-in hybrids).',
    },
    hc: {
      title: 'Hydrocarbons (HC)',
      desc:
        'Pollutant formed under combustion. Together with NOx and under the influence of sunlight, HC can form ground level ozone.',
    },
    icev: {
      title: 'Internal combustion engine vehicle (ICEV)',
      desc: 'Today’s conventional vehicle with a combustion engine.',
    },
    lsd: {
      title: 'Low sulphur diesel',
      desc: 'Diesel with less than 50 parts per million (ppm).',
    },
    nox: {
      abbr: 'NOx',
      title: 'Nitrogen Oxides (NOX)',
      desc: 'NOx is the common name for nitrogen oxide (NO) and nitrogen dioxide (NO2).',
    },
    noxAd: {
      title: '',
      desc: '',
    },
    ozone: {
      title: 'Ozone (03)',
      desc:
        'Ozone is found in the lower atmosphere (ground-level) and higher up in the stratosphere (stratospheric ozone). Ground-level ozone is a main component of photochemical smog and damages human health.',
    },
    pb: {
      title: 'Lead (PB)',
      desc:
        'Lead is a bluish or silver-gray soft metal. Two of its most important forms in terms of air pollution are tetraethyl lead and tetra methyl lead. These two forms of lead are used as "anti-knock" additives in petrol.',
    },
    phev: {
      title: 'Plug-in hybrids (PHEV)',
      desc:
        'Hybrid electric vehicle that can use petrol or diesel and can also be charged with electricity from the electric grid.',
    },
    pm: {
      title: 'Particulate Matter (PM)',
      desc: 'Particulate matter or small particles emitted from the exhaust.',
    },
    pm10: {
      title: 'PM 10',
      desc: 'Particles smaller than 10 microns.',
    },
    pm25: {
      title: 'PM 2.5',
      desc: 'Particles smaller than 2.5 microns.',
    },
    unleaded: {
      title: 'Unleaded petrol',
      desc: 'Petrol with no added lead. Standard in almost all countries today.',
    },
    ulsd: {
      title: 'Ultra low sulphur diesel (ULSD)',
      desc:
        'Diesel with very low sulphur levels (less than 15 ppm). Standard in the EU and in the US.',
    },
    respdisease: {
      title: 'Respiratory diseases',
      desc: 'Diseases that affect the lung system.',
    },
    retrofit: {
      title: 'Retrofit',
      desc: 'Fitting an exhaust emission control technology on an existing vehicle',
    },
    scr: {
      title: '',
      desc: '',
    },
    solarh2: {
      title: 'Solar hydrogen',
      desc:
        'Hydrogen derived from solar energy via electrolysis of water (solar electricity + water = hydrogen + oxygen)',
    },
    so2: {
      abbr: 'SO2',
      abbr2: 'SOx',
      title: 'Sulfur dioxide (SO2)',
      desc:
        'A colorless, heavy gas with a pungent smell, also known to be an irritant. SO2 causes acidification.',
    },
    spm: {
      title: 'Suspended particulate matter (SPM)',
      desc:
        'This refers to particles in the air of all sizes. SPM is a complex mixture of organic substances, present in the atmosphere both as solid particles and liquid droplets. They include fumes, smoke, dust and aerosols.',
    },
    threeWaycat: {
      title: '3-way catalyst',
      desc:
        'Catalyst that reduces three emissions — hydrocarbons, nitrogen oxides and carbon monoxide — from petrol fueled vehicles. Requires lead-free petrol.',
    },
    twoStroke: {
      title: '2-stroke engine',
      desc:
        'Older type of engine still used in smaller engine applications such as small mopeds, lawn mover, and old motorcycles. Most engines today are 4-strokes. “2-stroke” refers to the number of linear movements of the piston between every ignition.',
    },
    alignment: {
      title: 'Wheel alignment',
      desc: 'Correcting wheels that are not aligned (parallel).',
    },
    voc: {
      abbr: 'VOC',
      title: 'Volatile Organic Compounds (VOC)',
      desc:
        'Comprised of a wide range of individual substances including hydrocarbons (alkenes, alkenes, and aromatics), halocarbons (e.g. trichloroethylene), and oxygenates (alcohols, aldehydes, and hetons). They are all organic compounds and are volatile enough to exist as vapor in the atmosphere under normal conditions.',
    },
  },
}

export default translations
