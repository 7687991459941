/* eslint-disable default-param-last */
import actions from './actions'

const initialState = {
  activeHomeTab: '1',
  activeActionsTab: '1',
  activeInfluenceOfTechTab: '1',
  activeHealhImpactTab: '1',
  activeEnvImpactTab: '1',
  activeClimateChangeTab: '1',
  activeEcoDrivingTab: '1',
  activeMaintenanceTab: '1',
  activeFuelsTab: '1',
  activeDieselTab: '1',
  activeEVTab: '1',
  activeGenAltTab: '1',
  activeCaseStudiesTab: '1',
  activeStrategyTab: '1',
}

export default function pagesReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
