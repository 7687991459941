const actions = {
  SET_STATE: 'user/SET_STATE',
  LOGIN: 'user/LOGIN',
  // REGISTER: 'user/REGISTER',
  LOAD_CURRENT_ACCOUNT: 'user/LOAD_CURRENT_ACCOUNT',
  LOGOUT: 'user/LOGOUT',

  SAML_LOGIN: 'user/SAML_LOGIN',
  SAML_LOGOUT: 'user/SAML_LOGOUT',
  SAML_GET_CURRENT_ACCOUNT: 'SAML_GET_CURRENT_ACCOUNT',
}

export default actions
