/* eslint-disable no-underscore-dangle */
/* eslint-disable no-restricted-globals */
/* eslint-disable prefer-destructuring */
/* eslint-disable default-param-last */
import { createSlice } from '@reduxjs/toolkit'
// import produce from 'immer'

const invDetails = {
  ice: {
    num: null,
    mileage: null,
    annualFuel: null,
    avgMileage: null,
    avgFuel: null,
  },
  phev: {
    num: null,
    mileage: null,
    annualFuel: null,
    annualKWh: null,
    avgMileage: null,
    avgFuel: null,
    avgKWh: null,
  },
  bev: {
    num: null,
    mileage: null,
    annualKWh: null,
    avgMileage: null,
    avgKWh: null,
  },
}

const fleetTypes = {
  ices: {
    own: { ...invDetails.ice, recentNum: null },
    rent: invDetails.ice,
  },
  phevs: {
    own: { ...invDetails.phev, recentNum: null },
    rent: invDetails.phev,
  },
  bevs: {
    own: { ...invDetails.bev, recentNum: null },
    rent: invDetails.bev,
  },
}

export const initialState = {
  _id: null,
  noFetch: true,
  currentStep: 0,
  generalInfo: {
    fleetName: null,
    sulphurLevel: null,
    country: {
      label: '',
      value: '',
    },
    countryData: {
      ef: null,
      scope1: null,
      scope2: null,
      scope3: null,
    },
    currency: {
      label: '',
      value: '',
    },
    euroRate: null,
    annualRentalAmount: null,
    maintenanceAmount: null,
    insuranceAmount: null,
    includeRentals: true,
    customWasteValues: true,
  },
  passengerCarsGasoline: {
    preEuro: fleetTypes.ices,
    euro1: fleetTypes.ices,
    euro2: fleetTypes.ices,
    euro3: fleetTypes.ices,
    euro4: fleetTypes.ices,
    euro5: fleetTypes.ices,
    euro6: fleetTypes.ices,
    totals: fleetTypes.ices,
  },
  passengerCarsDiesel: {
    euro3: fleetTypes.ices,
    euro4: fleetTypes.ices,
    euro5: fleetTypes.ices,
    euro6: fleetTypes.ices,
    totals: fleetTypes.ices,
  },
  passengerCarsElectric: {
    hevs: fleetTypes.ices,
    phevs: fleetTypes.phevs,
    bevs: fleetTypes.bevs,
    totals: fleetTypes.phevs,
  },
  passengerSUVsGasoline: {
    preEuro: fleetTypes.ices,
    euro1: fleetTypes.ices,
    euro2: fleetTypes.ices,
    euro3: fleetTypes.ices,
    euro4: fleetTypes.ices,
    euro5: fleetTypes.ices,
    euro6: fleetTypes.ices,
    totals: fleetTypes.ices,
  },
  passengerSUVsDiesel: {
    preEuro: fleetTypes.ices,
    euro1: fleetTypes.ices,
    euro2: fleetTypes.ices,
    euro3: fleetTypes.ices,
    euro4: fleetTypes.ices,
    euro5: fleetTypes.ices,
    euro6: fleetTypes.ices,
    totals: fleetTypes.ices,
  },
  passengerSUVsElectric: {
    hevs: fleetTypes.ices,
    phevs: fleetTypes.phevs,
    bevs: fleetTypes.bevs,
    totals: fleetTypes.phevs,
  },
  ldvGasoline: {
    preEuro: fleetTypes.ices,
    euro1: fleetTypes.ices,
    euro2: fleetTypes.ices,
    euro3: fleetTypes.ices,
    euro4: fleetTypes.ices,
    euro5: fleetTypes.ices,
    euro6: fleetTypes.ices,
    totals: fleetTypes.ices,
  },
  ldvDiesel: {
    preEuro: fleetTypes.ices,
    euro1: fleetTypes.ices,
    euro2: fleetTypes.ices,
    euro3: fleetTypes.ices,
    euro4: fleetTypes.ices,
    euro5: fleetTypes.ices,
    euro6: fleetTypes.ices,
    totals: fleetTypes.ices,
  },
  mdvDiesel: {
    preEuro: fleetTypes.ices,
    euro1: fleetTypes.ices,
    euro2: fleetTypes.ices,
    euro3: fleetTypes.ices,
    euro4: fleetTypes.ices,
    euro5: fleetTypes.ices,
    euro6: fleetTypes.ices,
    totals: fleetTypes.ices,
  },
  hdvDiesel: {
    preEuro: fleetTypes.ices,
    euro1: fleetTypes.ices,
    euro2: fleetTypes.ices,
    euro3: fleetTypes.ices,
    euro4: fleetTypes.ices,
    euro5: fleetTypes.ices,
    euro6: fleetTypes.ices,
    totals: fleetTypes.ices,
  },
  motorcycles: {
    twoStroke: fleetTypes.ices,
    fourStroke: fleetTypes.ices,
    eBike: fleetTypes.bevs,
    totals: fleetTypes.phevs,
  },
  generators: {
    own: {
      num: null,
      annualFuel: null,
    },
    rent: {
      num: null,
      annualFuel: null,
    },
  },
  waste: {
    lubricants: null,
    tyres: null,
    batteries: null,
  },
  cargoTransport: {
    cargoDistance: null,
    cargoFuel: null,
    cargoTons: null,
  },
  totals: fleetTypes.phevs,
}
const updateNestedProperty = (object, keys, value, depth = 0) => {
  const key = keys[depth]

  if (depth === keys.length - 1) {
    return { ...object, [key]: value }
  }

  return {
    ...object,
    [key]: updateNestedProperty(object[key], keys, value, depth + 1),
  }
}

// Calculate totals for each category
const computeTotalsForCategory = (accurateState, category) => {
  if (
    category === 'generalInfo' ||
    category === 'generators' ||
    category === 'waste' ||
    category === 'cargoTransport'
  )
    return accurateState
  // Check if the specified category exists in the 'accurateState' object
  if (!accurateState[category]) {
    console.error(`Category '${category}' not found in the 'accurateState' object.`)
    return accurateState
  }

  // console.log('category:', category)
  // Initialize an empty object to hold the totals for each key
  const keyTotals = {}

  // Initialize the total objects for 'own' and 'rent' under the 'totals' subcategory for this category
  accurateState[category].totals = {
    own: {},
    rent: {},
  }

  // Get the keys under the 'own' subcategory of the first subcategory of this category
  const ownKeys = Object.keys(accurateState[category][Object.keys(accurateState[category])[0]]?.own)
  // console.log(category)

  // Iterate over each key (e.g., 'num', 'mileage', 'annualFuel', etc.)
  ownKeys.forEach((key) => {
    // console.log('category:', category)
    // Initialize the total values for this key
    keyTotals[key] = {
      own: 0,
      rent: 0,
    }

    // Iterate over each subcategory ('own' and 'rent') under this category
    Object.keys(accurateState[category]).forEach((subCategory) => {
      if (subCategory === 'totals') return // Skip the 'totals' subcategory

      // Accumulate the values for this key in both 'own' and 'rent' subcategories
      keyTotals[key].own += accurateState[category][subCategory]?.own[key] || 0
      // keyTotals[key].rent += accurateState[category][subCategory]?.rent[key] || 0
    })

    // Set the computed totals for this key in the 'totals' subcategory of this category
    accurateState[category].totals.own[key] = keyTotals[key]?.own
    if (accurateState?.generalInfo?.includeRentals)
      accurateState[category].totals.rent[key] = keyTotals[key]?.rent
  })

  return accurateState
}

// Update the overall inventory totals
const updateOverallTotals = (state) => {
  // Initialize a structure for the overall totals
  const overallTotals = {
    own: {},
    rent: {},
  }

  // Iterate over each category in the state
  Object.keys(state).forEach((category) => {
    // Skip categories without 'totals' or if they are the main 'totals' object itself
    if (!state[category]?.totals || category === 'totals') return

    // Aggregate 'own' and 'rent' totals for each key in the category's totals
    Object.keys(state[category]?.totals).forEach((subCategory) => {
      Object.keys(state[category]?.totals[subCategory]).forEach((key) => {
        overallTotals[subCategory][key] =
          (overallTotals[subCategory][key] || 0) + (state[category]?.totals[subCategory][key] || 0)
      })
    })
  })

  // Update the main 'totals' object in the state
  state.totals = overallTotals

  return state
}

// eslint-disable-next-line no-unused-vars
const inventorySlice = createSlice({
  name: 'inventory',
  initialState,
  reducers: {
    resetInventory: () => {
      // state.noFetch = true
      // console.log('Reset inventory:', initialState)
      return { ...initialState, noFetch: true }
    },
    currentInventoryStep: (state, action) => {
      state.currentStep = action.payload
    },
    setId: (state, { payload }) => {
      // console.log(payload)
      state._id = payload
      // return { ...state, _id: payload }
    },
    updateInventoryItem: (state, { payload }) => {
      const { key, value } = payload
      // console.log('payload:', payload)
      const [category] = key.split('.').slice(0, 1)

      let updatedState = updateNestedProperty(state, key.split('.'), value)
      updatedState = computeTotalsForCategory(updatedState, category)
      updatedState = updateOverallTotals(updatedState)

      // console.log(invTotals)
      return updatedState
    },
    updateInventory: (state, { payload }) => {
      // console.log('update inventory with:', payload)
      return { ...state, noFetch: true, ...payload }
    },
  },
})

export const { resetInventory, currentInventoryStep, setId, updateInventoryItem, updateInventory } =
  inventorySlice.actions
export default inventorySlice.reducer
