import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { API_URL } from '../../config'

const initialState = {
    currList: [],
    currencyData: {},
    baseCurrency: 'EUR',
    isLoading: true,
}

export const getCurrencies = createAsyncThunk('currencies/getCurrencies', async (_, thunkAPI) => {
    try {
        const response = await axios(`${API_URL}/currencies`)
        return thunkAPI.fulfillWithValue(response.data)
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response.data)
    }
})

const currenciesSlice = createSlice({
    name: 'currencies',
    initialState,
    reducers: {
        allCurrencies: (state, action) => {
            state.currList = action.payload // Assuming the intention was to update `currList`.
        },
        resetCurrencies: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(getCurrencies.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getCurrencies.fulfilled, (state, { payload }) => {
                state.isLoading = false
                state.currencyData = payload?.data || {} // Assuming payload.data is the intended structure; adjust if needed.
                state.currList = payload ? Object.keys(payload.data || {}).sort() : []
            })
            .addCase(getCurrencies.rejected, (state, { error }) => {
                console.log('Error fetching currencies data:', error)
                state.isLoading = false
            })
    },
})

export const { resetCurrencies, allCurrencies } = currenciesSlice.actions

export default currenciesSlice.reducer
