/* eslint-disable default-param-last */
import { injectIntl } from 'react-intl'
import actions from './actions'

const guestUser = ({ intl: { formatMessage } }) => {
  return {
    id: '0',
    name: formatMessage({ id: 'user.name' }),
    role: formatMessage({ id: 'user.role' }),
    organisation: formatMessage({ id: 'user.org' }),
    email: 'N/A',
    avatar: 'resources/images/avatars/avatar.png',
    photo: 'resources/images/avatars/avatar.png',
    authorized: true,
    permissions: formatMessage({ id: 'user.permissions' })
  }
}

const DEV = process.env.REACT_APP_AUTHENTICATED ? injectIntl(guestUser) : {}

const initialState = DEV

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
