const locale = {
  SITETITLE: 'Kit d’outils Clean Fleet',
  en: 'Anglais',
  fr: 'Français',
  home: 'Accueil',
  hello: 'Bonjour',
  anon: 'Anonyme',
  org: 'Organisation -fr',
  role: 'Rôle',
  email: 'Email',
  phone: 'Téléphone',
  logout: 'Connectez - Out',
  glossary: 'Glossaire des termes',
  loading: 'Chargement...',
  petrol: 'Essence',
  diesel: 'Diesel',
  sulphurLevel: `Teneur en soufre`,
  unleaded: `Essence sans plomb`,
  perYear: 'par an',
  viewProfile: 'Voir le profil',
  manCommitments: 'Manage Commitments',
  switchTheme: 'Changer de thème sombre / clair',
  switchLang: 'Changer de langue',
  toggleGloss: 'Basculer vers le glossaire',
  footer: {
    title: 'AVERTISSEMENT IMPORTANT',
    disclaimer:
      'Ce kit d’outils ne donne que des estimations approximatives de vos émissions, coûts et économies. Les résultats réels sont influencés par un certain nombre de facteurs, notamment : le comportement du conducteur, l’état des routes, les schémas de circulation, le type et la qualité du carburant, l’âge, l’état, la technologie et les normes du véhicule, le kilométrage, l’entretien, l’altitude et (dans le cas des véhicules électriques rechargeables) le type de centrale produisant l’électricité.',
    unep: '',
    privacy: '',
  },
  pageTitles: {
    calc: 'Outil de calcul',
    air: 'Pollution atmosphérique et transports',
    tech: 'Influence de la technologie sur les émissions',
    health: 'Impacts sur la santé',
    env: 'Impacts environnementaux',
    climate: 'Changement climatique et effet de serre',
    journey: 'Gestion des déplacements',
    eco: 'Écoconduite',
    maint: 'Maintenance',
    fuels: 'Carburants',
    diesel: 'Contrôle des émissions des véhicules diesel',
    petrol: 'Contrôle des émissions pour les véhicules à essence',
    cleanv: 'Véhicules plus propres',
    evs: 'Véhicules électriques',
    genalt: 'Alternatives aux générateurs',
    cases: 'Études de cas',
    strategy: 'Liste de contrôle de la stratégie',
    cng: 'Choisir un véhicule alternatif',
    dhl: 'La stratégie GoGreen de DHL',
    fedex: 'Stratégie de FedEx en matière de véhicules hybrides',
    jarkata: 'Programme d’inspection et de maintenance de Jakarta',
    tnt: 'L’échelle de progression de TNT',
    wfp: 'Le PAM réduit ses émissions et ses coûts',
    unama: 'Le chauffage à l’huile usagée de la MANUA',
    unhcr: 'Gestion globale de la flotte du HCR',
    unicef: 'L’UNICEF et le partage de flotte',
    ups: 'La stratégie environnementale d’UPS',
  },
}

export default locale
