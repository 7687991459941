import React, { Suspense } from 'react'
import { Tabs, Spin } from 'antd'
import i18next from 'i18next'
import style from './style.module.scss'
import En from '../i18n/en'
import Fr from '../i18n/fr'

i18next.addResourceBundle('en', 'glossary', En)
i18next.addResourceBundle('fr', 'glossary', Fr)
const GlossaryList = React.lazy(() => import('./GlossaryList'))

// const { TabPane } = Tabs

const List = ({ trans }) => {
  const tabItems = [
    {
      key: '1',
      label: trans('glossary'),
      children: (
        <Suspense fallback={<Spin tip={trans('loading')} />}>
          <div className="text-gray-6">
            <GlossaryList />
          </div>
        </Suspense>
      ),
    },
  ]

  return (
    <div>
      <Tabs
        className={`${style.tabs} ${style.glossaryTabs} vb-tabs-bordered`}
        defaultActiveKey="1"
        items={tabItems}
      />
    </div>
  )
}

export default List
