import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { BACKEND_URL } from '../../config'

const initialState = {
  list: [],
  isLoading: false,
}

export const getCountries = createAsyncThunk('countries/getCountries', async (_, thunkAPI) => {
  try {
    const response = await axios(`${BACKEND_URL}/api/countries`)
    return thunkAPI.fulfillWithValue(response.data)
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data)
  }
})

const countriesSlice = createSlice({
  name: 'countries',
  initialState,
  reducers: {
    allCountries: (state, action) => {
      state.list = action.payload
    },
    resetCountries: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCountries.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getCountries.fulfilled, (state, { payload }) => {
        state.isLoading = false
        state.list = payload
      })
      .addCase(getCountries.rejected, (state, { error }) => {
        console.log('Error fetching countries data:', error)
        state.isLoading = false
      })
  },
})

export const { resetCountries, allCountries } = countriesSlice.actions

export default countriesSlice.reducer
