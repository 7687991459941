/* eslint-disable default-param-last */
import actions from './actions'

const initialState = {
  menuData: [],
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case 'UPDATE_SELECTED_KEYS':
      return {
        ...state,
        menu: {
          ...state.menu,
          selectedKeys: action.payload,
        },
      }
    default:
      return state
  }
}
