/* eslint-disable react/no-deprecated */
import React from 'react'
import ReactDOM from 'react-dom'
import './i18n'
import { createHashHistory } from 'history'
// import { createStore, applyMiddleware, compose } from 'redux'
import { Provider } from 'react-redux'
// import { logger } from 'redux-logger'
import createSagaMiddleware from 'redux-saga'
import { routerMiddleware } from 'connected-react-router'
import { configureStore } from '@reduxjs/toolkit'
import thunk from 'redux-thunk'

import StylesLoader from './stylesLoader'
import reducers from './redux/reducers'
import sagas from './redux/sagas'
import Localization from './localization'
import Router from './router'
// import * as serviceWorker from './serviceWorker'

// mocking api
// import 'services/axios/fakeApi'

// middlewared
const history = createHashHistory()
const sagaMiddleware = createSagaMiddleware()

// const routeMiddleware = routerMiddleware(history)

// const middlewares = [sagaMiddleware, routeMiddleware, thunk]
// const middleware = [
//   ...getDefaultMiddleware(),
//   thunk,
//   sagaMiddleware,
//   routerMiddleware(history),
// ];
// if (process.env.NODE_ENV === 'development') {
//   middlewares.push(logger)
// }
// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
// const store = createStore(reducers(history), composeEnhancers(applyMiddleware(...middlewares)))


const store = configureStore({
  reducer: reducers(history),
  middleware: () => {
    return [
      // ...getDefaultMiddleware(),
      thunk,
      sagaMiddleware,
      routerMiddleware(history),
    ];
  },
  // devTools: process.env.NODE_ENV !== 'production',
});

sagaMiddleware.run(sagas)


ReactDOM.render(
  <Provider store={store}>
    <StylesLoader>
      <Localization>
        <Router history={history} />
      </Localization>
    </StylesLoader>
  </Provider>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.register()

// Check for browser support of service worker
if ('serviceWorker' in navigator) {
  navigator.serviceWorker
    .register('./serviceWorker.js')
    .then((registration) => {
      // Successful registration
      console.log('Service Worker scope:', registration.scope)
    })
    .catch((err) => {
      // Failed registration, service worker won’t be installed
      console.log('Whoops. Service worker registration failed, error:', err)
    })
}
export { store, history }
