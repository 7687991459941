/* eslint-disable consistent-return */
import { notification } from 'antd'

import { BACKEND_URL } from '../../config'

export async function samlLogin() {
  try {
    const loginReq = await fetch(`${BACKEND_URL}/login`)
    // console.log('loginurl', loginReq)
    if ((await loginReq.status) !== 200 && (await loginReq.status) !== 201) {
      throw new Error('SAML Login Failed')
    }
    const loginRes = await loginReq.json()
    // console.log(await loginRes)
    window.location.href = loginRes
  } catch (error) {
    notification.error({
      message: error.code,
      description: error.message,
    })
  }
}

export async function samlLogout() {
  try {
    window.location.href = `${BACKEND_URL}/logout`
    // const logoutReq = await fetch(`${BACKEND_URL}/logout`)
    // if ((await logoutReq.status) !== 200 && (await logoutReq.status) !== 201) {
    //   throw new Error('SAML logout failed')
    // }
    // const logoutRes = await logoutReq.json()
    // console.log(logoutRes)
    // if (logoutRes.status === 200) window.location.reload()
    // window.location.href = await logoutReq.json()
  } catch (error) {
    console.log(error.message)
    // notification.error({
    //   message: error.code,
    //   description: error.message,
    // })
  }
}

export async function authStatusCheck() {
  try {
    const statusReq = await fetch(`${BACKEND_URL}/getUserDetails`, {
      credentials: 'include',
    })
    const statusRes = await statusReq.json()
    return statusRes
  } catch (err) {
    console.log(err)
  }
}

export async function getUserDetails() {
  try {
    const userReq = await fetch(`${BACKEND_URL}/getUserDetails`, {
      credentials: 'include',
    })
    const userRes = await userReq.json()
    if (userRes.status === 'loggedOut') {
      // clear user details in local storage
    }

    if ((await userReq.status) !== 200 && (await userReq.status) !== 201) {
      throw new Error('Cannot get user details')
    }
    const userDetails = await userReq.json()
    // console.log(userDetails)
    if (userDetails.status === 302 || userDetails.status === 'login') return null
    // const userDetails = await userReq.json()
    // console.log(userDetails)
    return userDetails
  } catch (error) {
    console.log(error)
    notification.error({
      message: error.code,
      description: error.message,
    })
  }
}
